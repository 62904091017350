import React from 'react';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export const PB_Eye = (props) => {
  const {color = charcoal, className = '', ratio = 1} = props;

  // default dimensions
  const width = 32;
  const height = 32;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.8904 15.5609C30.8227 15.4533 29.2104 12.8993 26.5856 10.3094C25.0352 8.78002 23.4535 7.55886 21.8839 6.68039C19.8917 5.56542 17.9124 5 16 5C14.0876 5 12.1083 5.56542 10.1161 6.68039C8.54652 7.55886 6.96416 8.78002 5.41436 10.3094C2.78965 12.8986 1.17729 15.4533 1.10963 15.5609C0.963457 15.7946 0.963457 16.1008 1.10963 16.3339C1.17729 16.4414 2.78965 18.9954 5.41436 21.5853C6.9648 23.1147 8.54652 24.3359 10.1161 25.2143C12.1083 26.3293 14.0876 26.8947 16 26.8947C17.9124 26.8947 19.8917 26.3293 21.8839 25.2143C23.4535 24.3359 25.0358 23.1147 26.5856 21.5853C29.2104 18.9954 30.8227 16.4414 30.8904 16.3339C31.0365 16.1001 31.0365 15.7939 30.8904 15.5609ZM25.7041 20.5876C23.4209 22.8361 19.8841 25.5157 15.9994 25.5157C12.1146 25.5157 8.57843 22.8355 6.29458 20.5876C4.35796 18.681 2.96837 16.7503 2.42198 15.9477C2.96837 15.1451 4.35796 13.2144 6.29458 11.3078C8.57907 9.05928 12.1159 6.37907 16 6.37907C19.8841 6.37907 23.4209 9.05928 25.7048 11.3072C27.6414 13.2137 29.031 15.1444 29.5774 15.947C29.031 16.7496 27.6414 18.6803 25.7048 20.5869L25.7041 20.5876Z"
        fill={color}
        stroke={color}
      />
      <path
        d="M16 9.93909C12.7285 9.93909 10.0677 12.6345 10.0677 15.947C10.0677 19.2595 12.7292 21.9549 16 21.9549C19.2708 21.9549 21.9323 19.2595 21.9323 15.947C21.9323 12.6345 19.2708 9.93909 16 9.93909ZM16 20.5765C13.4795 20.5765 11.4295 18.4996 11.4295 15.9477C11.4295 13.3957 13.4802 11.3188 16 11.3188C18.5198 11.3188 20.5706 13.3957 20.5706 15.9477C20.5706 18.4996 18.5198 20.5765 16 20.5765Z"
        fill={color}
        stroke={color}
      />
    </svg>
  );
};
