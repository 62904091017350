import React from 'react';
import {fog} from '~sass/pb_styleguide/base/_exports.sass';

export const PB_SocialFb = (props) => {
  const {color = fog, ratio = 1, className} = props;

  // default dimensions
  const width = 32;
  const height = 32;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${width} ${height}`}
      style={style}
      className={className}
    >
      <path
        d="M16 2.66663C8.636 2.66663 2.66666 8.63596 2.66666 16C2.66666 22.6546 7.54266 28.1706 13.9173 29.172V19.8533H10.5307V16H13.9173V13.0626C13.9173 9.72129 15.9067 7.87596 18.9533 7.87596C20.412 7.87596 21.9373 8.13596 21.9373 8.13596V11.416H20.2573C18.6 11.416 18.084 12.444 18.084 13.4986V16H21.7813L21.1907 19.8533H18.084V29.172C24.4573 28.172 29.3333 22.6533 29.3333 16C29.3333 8.63596 23.364 2.66663 16 2.66663Z"
        fill={color}
      />
    </svg>
  );
};
