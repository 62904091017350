import cx from 'classnames';
import {shallowEqual, useSelector} from 'react-redux';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {AddToCalendar} from '~/common/_pb_components/molecules/AddToCalendar';
import {useIsLargishAndUp} from '~/common/hooks/useMatchQuery';
import {selectCalendarLinks, selectIsPreviewMode} from '~/SignUpSheets/reducers/sheet/selectors';
import {selectBlueprintType} from '~/SignUpSheets/reducers/blueprint/selectors';
import {useFormattedSheetDate} from './hooks/useFormattedSheetDate';
import {BLUEPRINT_TYPE_ITEMS} from '~/SignUpSheets/reducers/blueprint/constants';

export const DetailsDate = ({className}) => {
  const isPreviewMode = useSelector(selectIsPreviewMode);
  const calendarLinks = useSelector(selectCalendarLinks, shallowEqual);
  const blueprintType = useSelector(selectBlueprintType);
  const isLargishAndUp = useIsLargishAndUp();
  const formattedDate = useFormattedSheetDate();
  const isBluePrintTypeItems = blueprintType === BLUEPRINT_TYPE_ITEMS;

  return (
    <div className={cx('signup-sheet__view-details__when', className)}>
      <div className="signup-sheet__view-details__date-time">
        {(formattedDate || isPreviewMode) && (
          <Typography
            variant={isLargishAndUp ? 'header5' : 'paragraph3'}
            semibold={!isLargishAndUp}
          >
            {isPreviewMode && !formattedDate ? '[Date]' : formattedDate}
          </Typography>
        )}
      </div>
      {isBluePrintTypeItems && (
        <div className="signup-sheet__view-details__calendar-container">
          <AddToCalendar
            className="signup-sheet__view-details__action-btn"
            closeMenu={() => {}}
            isButton
            googleCalUrl={calendarLinks.google_cal_url}
            iCalUrl={calendarLinks.ical_url}
            outlookUrl={calendarLinks.outlook_cal_url}
            preview={isPreviewMode}
            buttonSize={isLargishAndUp ? 'large' : 'small'}
          />
        </div>
      )}
    </div>
  );
};
