import React from 'react';
import {fog} from '~sass/pb_styleguide/base/_exports.sass';

export const PB_SocialTwi = (props) => {
  const {color = fog, ratio = 1, className} = props;

  // default dimensions
  const width = 32;
  const height = 32;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${width} ${height}`}
      style={style}
      className={className}
    >
      <path
        d="M29.5493 7.54124C28.5314 7.99149 27.4519 8.2872 26.3467 8.41857C27.5117 7.72181 28.3836 6.62525 28.8 5.33324C27.7067 5.9839 26.508 6.4399 25.2587 6.68657C24.4195 5.78867 23.3071 5.19319 22.0946 4.99268C20.8821 4.79218 19.6372 4.99789 18.5537 5.57784C17.4701 6.15779 16.6085 7.07948 16.1028 8.19963C15.5971 9.31977 15.4757 10.5756 15.7573 11.7719C13.5401 11.6608 11.3711 11.0846 9.39104 10.0808C7.41098 9.07698 5.66416 7.66797 4.26399 5.94524C3.76838 6.7965 3.50793 7.7642 3.50932 8.74924C3.50932 10.6826 4.49332 12.3906 5.98932 13.3906C5.104 13.3627 4.23817 13.1236 3.46399 12.6932V12.7626C3.46426 14.0502 3.90981 15.2981 4.72512 16.2946C5.54042 17.2912 6.6753 17.9752 7.93732 18.2306C7.11548 18.4533 6.25373 18.4861 5.41732 18.3266C5.77315 19.4349 6.46666 20.4042 7.40078 21.0987C8.33489 21.7933 9.46283 22.1783 10.6267 22.1999C9.46996 23.1083 8.14556 23.7799 6.72916 24.1761C5.31277 24.5724 3.83216 24.6856 2.37199 24.5092C4.92092 26.1485 7.88812 27.0187 10.9187 27.0159C21.176 27.0159 26.7853 18.5186 26.7853 11.1492C26.7853 10.9092 26.7787 10.6666 26.768 10.4292C27.8598 9.64013 28.8021 8.66259 29.5507 7.54257L29.5493 7.54124Z"
        fill={color}
      />
    </svg>
  );
};
