import React from 'react';
import {ResetStyle} from '~/CreateFabricInvitation/routes/EventCreateDesign/components/CardEditor/actions/ResetStyle/ResetStyle';
import {PB_CloseLine} from '~/common/svg/PB_CloseLine';
import './ContentBase.sass';
import {Typography} from '~/common/_pb_components/atoms/Typography';

export const ContentBase = ({
  title,
  children,
  onCloseClick,
  bodyStyle = {},
  noBodyPadding,
  visible,
  style,
  onResetClick,
  resetDataQaId,
  closeDataQaId,
  hideResetButton = false,
}) =>
  visible && (
    <div className="content_base">
      <div className="content_base__header">
        {!hideResetButton && (
          <div>
            <ResetStyle
              label="Reset"
              style={style}
              onResetClick={onResetClick}
              dataQaId={resetDataQaId}
            />
          </div>
        )}
        <div className="content_base__header__title">
          <Typography as="h1" variant="paragraph4" size="medium" color="pebble">
            {title}
          </Typography>
        </div>
        <button
          className="content_base__header__close_btn unstyled-button"
          onClick={onCloseClick}
          data-qa-id={closeDataQaId}
          data-impression-tracking="true"
        >
          <PB_CloseLine ratio={0.75} />
        </button>
      </div>
      <div
        className={`content_base__body ${noBodyPadding ? 'content_base__body--no-padding' : ''}`}
        style={bodyStyle}
      >
        {children}
      </div>
    </div>
  );
