import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useRequestLogin} from '~/common/fabric/Card/components/LoginModal/hooks/useRequestLogin';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {PB_Checkmark as CheckmarkIcon} from '~/common/svg/PB_Checkmark';
import {Checkbox} from '~/common/_pb_components/atoms/Checkbox';

import {emerald} from '~sass/pb_styleguide/base/_exports.sass';
import './PurchasedController.sass';

export const PurchasedController = ({
  purchased = false,
  onDeclarePurchased,
  onCancel,
  'data-qa-value': dataQaValue,
  uuid,
}) => {
  const {search} = useLocation();
  const {requestLogin} = useRequestLogin();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const initialPurchased = useRef(purchased);
  const inputRef = useRef(null);
  const checkboxId = useMemo(() => `purchased-checkbox-${uuid}`, [uuid]);
  const purchasedId = queryParams.get('purchased');
  const [autoClicked, setAutoClicked] = useState(false);

  const promptLogin = async () => {
    await requestLogin();
  };

  const handleCheckboxToggle = useCallback(
    async (e) => {
      e.preventDefault();
      await promptLogin();
      if (purchased) onCancel();
      else onDeclarePurchased();
    },
    [purchased, onDeclarePurchased, onCancel]
  );

  useEffect(() => {
    const onQueryChange = async () => {
      if (!autoClicked && purchasedId === uuid) {
        await promptLogin();
        setTimeout(() => inputRef.current?.click(), 1000);
        setAutoClicked(true);
      }
    };

    onQueryChange();
  }, [autoClicked, purchasedId, uuid]);

  if (initialPurchased.current) {
    return (
      <div className="purchased-controller__already-purchased">
        <CheckmarkIcon ratio={0.5} color={emerald} />
        <Typography variant="paragraph4" color="charcoal">
          Purchased
        </Typography>
      </div>
    );
  }

  return (
    <Checkbox
      label="I've purchased"
      labelVariant="paragraph4"
      labelClassName="purchased-controller"
      checked={purchased}
      onChange={handleCheckboxToggle}
      data-qa-id="purchased-affiliate-product"
      data-qa-value={dataQaValue}
      id={checkboxId}
      inputRef={inputRef}
    />
  );
};

PurchasedController.propTypes = {
  purchased: PropTypes.bool,
  onDeclarePurchased: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  'data-qa-value': PropTypes.string,
  uuid: PropTypes.string.isRequired,
};
