import {useEffect, useState} from 'react';
import {PB_Checkmark as PBCheckmark} from '~/common/svg/PB_Checkmark';
import {PB_Circle as PBCircle} from '~/common/svg/PB_Circle';

export const Spinner = ({isLoading, isError, isSuccess}) => {
  // TODO: implement isError?
  const [showLoading, setShowLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    const timeoutIds = [];
    timeoutIds.push(
      setTimeout(() => {
        setShowLoading(isLoading);
        setShowSuccess(isSuccess && !isLoading);
      }, 1000)
    );

    if (isSuccess && !isLoading) {
      timeoutIds.push(
        setTimeout(() => {
          setShowSuccess(false);
        }, 2000)
      );
    }

    return () => {
      timeoutIds.forEach(clearTimeout);
    };
  }, [isLoading, isSuccess, isError]);

  return (
    <>
      {showLoading && <PBCircle className="spinner-circle-animation" ratio={0.5} />}
      {showSuccess && <PBCheckmark className="spinner-checkmark-animation" ratio={0.5} />}
    </>
  );
};
