import React from 'react';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export const PB_LinkIcon = (props) => {
  const {color = charcoal, className = '', ratio = 1} = props;

  // default dimensions
  const width = 24;
  const height = 25;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7442 1.83301C14.4393 1.83301 13.1879 2.35143 12.2652 3.2742L10.8501 4.6895C10.4921 5.0475 10.4921 5.6279 10.8501 5.98587C11.2081 6.34383 11.7885 6.3438 12.1465 5.9858L13.5617 4.57049C14.1405 3.99156 14.9256 3.66634 15.7442 3.66634C16.5629 3.66634 17.348 3.99156 17.9268 4.57049C18.5057 5.14943 18.8309 5.93465 18.8309 6.75342C18.8309 7.57219 18.5057 8.35742 17.9268 8.93635L15.0957 11.7669C14.5168 12.3455 13.7319 12.6706 12.9134 12.6706C12.095 12.6706 11.3101 12.3455 10.7312 11.7668C10.3731 11.4089 9.79275 11.409 9.43482 11.767C9.0769 12.1251 9.07699 12.7055 9.43503 13.0634C10.3577 13.9858 11.6089 14.5039 12.9134 14.5039C14.218 14.5039 15.4692 13.9857 16.3919 13.0634L19.2233 10.2326C20.1459 9.30988 20.6643 8.05837 20.6643 6.75342C20.6643 5.44848 20.1459 4.19696 19.2233 3.2742C18.3006 2.35143 17.0491 1.83301 15.7442 1.83301ZM7.17197 20.1663C8.47687 20.1663 9.72831 19.6479 10.651 18.7252L12.0662 17.3098C12.4241 16.9518 12.4241 16.3714 12.0661 16.0135C11.7081 15.6555 11.1277 15.6555 10.7697 16.0135L9.35456 17.4289C8.77569 18.0078 7.99058 18.333 7.17197 18.333C6.35336 18.333 5.56826 18.0078 4.98939 17.4289C4.41051 16.8499 4.08529 16.0647 4.08529 15.2459C4.08529 14.4272 4.41051 13.6419 4.98939 13.063L7.82054 10.2325C8.39941 9.65382 9.18435 9.32876 10.0028 9.32876C10.8212 9.32876 11.6062 9.65383 12.185 10.2325C12.5431 10.5904 13.1235 10.5903 13.4814 10.2323C13.8393 9.87427 13.8392 9.29387 13.4812 8.93595C12.5585 8.01359 11.3074 7.49543 10.0028 7.49543C8.69818 7.49543 7.44698 8.01362 6.52432 8.93598L3.69296 11.7667C2.77029 12.6895 2.25195 13.941 2.25195 15.2459C2.25195 16.5509 2.77029 17.8024 3.69296 18.7252C4.61564 19.6479 5.86707 20.1663 7.17197 20.1663Z"
        fill={color}
      />
    </svg>
  );
};
