import React from 'react';
import {gray4} from '~sass/reinvention_styleguide/base/_exports.sass';

export const MoreIcon = (props) => {
  const {color = gray4, className = '', ratio = 1} = props;

  // default dimensions
  const width = 32;
  const height = 32;

  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${width} ${height}`}
      style={style}
      className={className}
    >
      <path
        d="M6 14C4.9 14 4 14.9 4 16C4 17.1 4.9 18 6 18C7.1 18 8 17.1 8 16C8 14.9 7.1 14 6 14ZM26 14C24.9 14 24 14.9 24 16C24 17.1 24.9 18 26 18C27.1 18 28 17.1 28 16C28 14.9 27.1 14 26 14ZM16 14C14.9 14 14 14.9 14 16C14 17.1 14.9 18 16 18C17.1 18 18 17.1 18 16C18 14.9 17.1 14 16 14Z"
        fill={color}
      />
    </svg>
  );
};
