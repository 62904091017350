import React from 'react';
import urls from 'urls';

export const LegalCopy = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <p className="legal">
        Evite&reg; and Life's Better Together<sup>&trade;</sup> are trademarks of Evite, Inc. in the
        United States and other countries. The Evite logo and all other Evite-related trademarks are
        trademarks of Evite, Inc. Please use this mark only to refer to our services.
      </p>
      <br />
      <p className="legal--privacy legal">
        <a href={urls.get('privacy')} data-qa-id="footer_privacy">
          Privacy Policy
        </a>
        <a href={urls.get('terms')} data-qa-id="footer_terms">
          Terms of Service
        </a>
        <a
          href={`${urls.get('site_map')}/#information_collected_by_third_parties`}
          data-qa-id="footer_ads"
        >
          About Ads
        </a>

        {window.isAuthenticated ? (
          <a href={urls.get('data_privacy')} data-qa-id="footer_donotsell">
            Do Not Sell My Info
          </a>
        ) : (
          <a
            href={urls.get('logged_out_data_privacy')}
            target="_blank"
            rel="noreferrer noopener"
            data-qa-id="footer_donotsell"
          >
            Do Not Sell My Info
          </a>
        )}
      </p>
      <p className="legal copyright">&copy; {currentYear} Evite. All Rights Reserved.</p>
    </>
  );
};
