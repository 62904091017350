import cx from 'classnames';
import {useSelector} from 'react-redux';
import {PB_Checkmark as PBCheckmark} from '~/common/svg/PB_Checkmark';
import {Button} from '~/common/_pb_components/atoms/Button';
import {selectIsPreviewMode} from '~/SignUpSheets/reducers/sheet/selectors';

export const SignUpButton = ({toggleSelected, slot, isSelected, expired}) => {
  const isPreviewMode = useSelector(selectIsPreviewMode);

  return (
    <Button
      variant="primary"
      onClick={() => toggleSelected(slot)}
      size="small"
      className={cx('volunteer-signup-row__sign-up-btn', {
        'volunteer-signup-row__sign-up-btn--selected': isSelected,
        'volunteer-signup-row__sign-up-btn--expired': expired,
      })}
      data-qa-id="sign-up-btn"
      disabled={isPreviewMode}
    >
      {isSelected ? (
        <>
          <PBCheckmark ratio={0.5} /> Selected
        </>
      ) : (
        'Sign up'
      )}
    </Button>
  );
};
