import React from 'react';
import urls from 'urls';
import {SocialButtons} from '~/footer/SocialButtons';
import {MobileApps} from '~/footer/MobileApps';
import {LegalCopy} from '~/footer/LegalCopy';
import {BlockThisHost} from '~/footer/BlockThisHost';
import {NewsletterSignup} from '~/footer/NewsletterSignup';
import * as utils from '~/ViewInvitation/utils';

export const Footer = () => {
  const preview = utils.isPreview();
  const cmsUrl = urls.get('content_pages');

  return (
    <div className="home-row">
      <div className="top-row social-and-apps">
        <SocialButtons />
        <MobileApps />
      </div>
      <div className="second-row">
        <ul className="links">
          <li>
            <a
              href={urls.get('home')}
              aria-label="evite home"
              className="footer-logo"
              data-qa-id="footer_logo"
            />
          </li>
          <li>
            <a href={`${cmsUrl}/about-us`} data-qa-id="footer_aboutus">
              About Us
            </a>
          </li>
          <li>
            <a href={`${cmsUrl}/careers`} data-qa-id="footer_careers">
              Careers
            </a>
          </li>
          <li>
            <a href={`${cmsUrl}/press`} data-qa-id="footer_press">
              Press
            </a>
          </li>
          <li>
            <a href={`${cmsUrl}/blog`} data-qa-id="footer_blog">
              Blog
            </a>
          </li>
          <li>
            <a href="/egift-cards/" data-qa-id="footer_giftcards">
              eGift Cards
            </a>
          </li>
          <li>
            <a href="/pages/affiliate/" data-qa-id="footer_affiliate">
              Affiliate
            </a>
          </li>
          <li>
            <a href="https://help.evite.com/" data-qa-id="footer_help">
              Help
            </a>
          </li>
          <li>
            <a href={urls.get('site_map')} data-qa-id="footer_sitemap">
              Site Map
            </a>
          </li>
        </ul>

        <NewsletterSignup />
      </div>
      {!preview && <BlockThisHost />}
      <LegalCopy />
    </div>
  );
};
