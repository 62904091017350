import {useCallback, useState} from 'react';
import {shallowEqual, useDispatch, useSelector, useStore} from 'react-redux';
import {v4} from 'uuid';
import {toastAlert} from '~/common/_pb_components/atoms/Toast';
import {useSheetActions} from '~/SignUpSheets/hooks/useSheetActions';
import {
  DELETE_SIGNUP_OPTION,
  MOVE_SIGNUP_OPTION_DOWN,
  MOVE_SIGNUP_OPTION_UP,
  UPDATE_ERRORS,
  UPDATE_SHEET_DETAILS,
} from '~/SignUpSheets/reducers/sheet/constants';
import {
  selectErrors,
  selectNumSignupOptions,
  selectNumSignupSlots,
  selectSheetId,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {isValidDaysNumber, isValidSlotsNumber} from '~/SignUpSheets/utils/validation';
import {entryHasProductLink} from '~/SignUpSheets/utils/misc';
import {duplicateSlot} from '~/SignUpSheets/utils/schema';
import {useAffiliateActions} from './useAffiliateActions';

export const useUpdateSheet = () => {
  const store = useStore();
  const dispatch = useDispatch();
  const sheetId = useSelector(selectSheetId);
  const numSignupOptions = useSelector(selectNumSignupOptions);
  const numSignupSlots = useSelector(selectNumSignupSlots);
  const errors = useSelector(selectErrors, shallowEqual);
  const {autoSave, isSavingSheet} = useSheetActions();
  const {processProductLinks} = useAffiliateActions();
  const [tryAutoSaveOnce, setTryAutoSaveOnce] = useState(false);

  const validateSheet = useCallback(
    (payload = {signup_options: []}, appendIfArray = false) => {
      const slots = payload.signup_options?.flatMap((so) => so.slots) ?? [];
      const days = payload.signup_options ?? [];

      const newNumSignupOptions = appendIfArray ? numSignupOptions + days.length : days.length;
      const newNumSignupSlots = appendIfArray ? numSignupSlots + slots.length : slots.length;

      const validSlotsNumber = isValidSlotsNumber(newNumSignupSlots);
      const validDaysNumber = isValidDaysNumber(newNumSignupOptions);

      const sheetErrors = [validSlotsNumber, validDaysNumber]
        .filter(({isValid}) => !isValid)
        .map(({message}) => message);
      if (sheetErrors.length > 0) {
        sheetErrors.map((error) =>
          toastAlert(error, {
            type: 'error',
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        );
        throw new Error('Sheet validation failed');
      }
    },
    [numSignupOptions, numSignupSlots]
  );

  const updateSheetDetails = useCallback(
    ({payload, appendIfArray = false, saveChanges = false, errorsToClear = []}) => {
      validateSheet(payload, appendIfArray);

      dispatch({
        type: UPDATE_SHEET_DETAILS,
        payload: {
          pristine: false,
          appendIfArray,
          ...payload,
        },
      });

      // TODO: If we're going to refactor this to allow changing multiple fields at once, we will have to refactor this section
      // since this if statement and the wishlist error handler in the Redux store
      // is built on the assumption that only one field is being updated at a time
      if (!('has_unsaved_wishlists' in payload)) {
        const errorMessages = [...errors];
        errorsToClear.forEach((loc) => {
          const idx = errorMessages.findIndex((e) => e.loc.every((l, i) => l === loc?.[i]));
          if (idx >= 0) errorMessages.splice(idx, 1);
        });
        dispatch({
          type: UPDATE_ERRORS,
          payload: errorMessages,
        });
      }
      if (!sheetId && saveChanges && !isSavingSheet && !tryAutoSaveOnce) {
        // Auto save the first time any Builder or Wishlist changes are made
        autoSave().catch((e) => evite.error('Failed to save sheet', e));
        setTryAutoSaveOnce(true);
      }
    },
    [sheetId, autoSave, isSavingSheet, errors, tryAutoSaveOnce, validateSheet]
  );

  const deleteSignupOption = useCallback((index) => {
    dispatch({
      type: DELETE_SIGNUP_OPTION,
      payload: {index},
    });
  }, []);

  const duplicateSignupOption = useCallback(
    (index) => {
      const signupOptions = [...store.getState().sheet.signup_options];
      if (index < 0 || index >= signupOptions.length) return;

      const item = {
        ...signupOptions[index],
        uuid: v4(),
        new: true,
      };
      if (item?.slots) {
        item.slots = item.slots.map((s) => duplicateSlot(s));
      }
      signupOptions.splice(index + 1, 0, item);

      updateSheetDetails({
        payload: {signup_options: signupOptions},
        saveChanges: true,
      });
      if (entryHasProductLink(item) || item.slots?.some((slot) => entryHasProductLink(slot))) {
        processProductLinks();
      }
    },
    [store, updateSheetDetails, processProductLinks]
  );

  const moveSignupOptionUp = useCallback((index) => {
    dispatch({
      type: MOVE_SIGNUP_OPTION_UP,
      payload: {index},
    });
  }, []);

  const moveSignupOptionDown = useCallback((index) => {
    dispatch({
      type: MOVE_SIGNUP_OPTION_DOWN,
      payload: {index},
    });
  }, []);

  const copySlotsToEmptyDates = useCallback(
    (index) => {
      const signupOptions = store.getState().sheet.signup_options;
      if (index < 0 || index >= signupOptions.length) return;
      const item = signupOptions[index];
      if (!item.slots) return;

      // Copy slots to date where slots are empty
      const newSignupOptionsList = signupOptions.map((option) => {
        if (option.uuid === item.uuid) return option;
        return {
          ...option,
          slots: [
            // "item" is the copy source, "option" is the paste target
            ...item.slots.map((s) => duplicateSlot(s, item.all_day, option.all_day, true)),
            ...(option.slots ?? []),
          ],
        };
      });
      updateSheetDetails({
        payload: {signup_options: newSignupOptionsList},
        saveChanges: true,
      });
      if (item.slots?.some((slot) => entryHasProductLink(slot))) {
        processProductLinks();
      }
    },
    [store, updateSheetDetails, processProductLinks]
  );

  return {
    updateSheetDetails,
    deleteSignupOption,
    duplicateSignupOption,
    moveSignupOptionUp,
    moveSignupOptionDown,
    copySlotsToEmptyDates,
  };
};
