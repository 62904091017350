import {useCallback, useState, useEffect} from 'react';
import {useStore} from 'react-redux';
import {useProcessAffiliateUrlsMutation} from '../api/affiliate';
import {entryHasProductLink} from '../utils/misc';

export const useAffiliateActions = () => {
  const [processAffiliateUrls, {data}] = useProcessAffiliateUrlsMutation({
    fixedCacheKey: 'affiliate-urls',
  });
  const store = useStore();

  const [linkPreviews, setLinkPreviews] = useState({});

  useEffect(() => {
    if (data) setLinkPreviews(data);
  }, [data]);

  const processProductLinks = useCallback(
    async (sos = store.getState().sheet.signup_options) => {
      const urls = {};
      for (const so of sos) {
        if (entryHasProductLink(so)) {
          urls[so.uuid] = {
            url: so.product_link.url,
          };
        }
        for (const slot of so.slots ?? []) {
          if (entryHasProductLink(slot)) {
            urls[slot.slot_id] = {
              url: slot.product_link.url,
            };
          }
        }
      }
      if (Object.keys(urls).length > 0) {
        await processAffiliateUrls(urls);
      }
    },
    [store, processAffiliateUrls]
  );

  return {processProductLinks, linkPreviews, processAffiliateUrls};
};
