import {SCOPE_USER} from '~/SignUpSheets/reducers/sheet/constants';
import {
  BLUEPRINT_TYPE_DATE,
  BLUEPRINT_TYPE_DATETIME,
  BLUEPRINT_TYPE_DATETIME_WITH_QUANTITY,
} from '~/SignUpSheets/reducers/blueprint/constants';

export const selectBlueprint = ({blueprint}) => blueprint;
export const selectBlueprintId = ({blueprint, sheet}) =>
  sheet.blueprint_id ?? blueprint.blueprint_id;
export const selectShowWhen = ({blueprint}) => blueprint.show_when;
export const selectShowWhere = ({blueprint}) => blueprint.show_where;
export const selectBackgroundUrls = ({blueprint}) => blueprint.background_urls;
export const selectFieldSchema = ({blueprint}) => blueprint.field_schema;
export const selectMaxOptions = ({blueprint}) => blueprint.max_rows;
export const selectBlueprintType = ({blueprint}) => blueprint?.blueprint_type;
export const selectIsDateTimeBlueprint = ({blueprint}) =>
  [BLUEPRINT_TYPE_DATETIME, BLUEPRINT_TYPE_DATETIME_WITH_QUANTITY].includes(
    blueprint?.blueprint_type
  );
export const selectShouldShowAdvancedOptions = ({blueprint}) =>
  [BLUEPRINT_TYPE_DATETIME_WITH_QUANTITY].includes(blueprint?.blueprint_type);
export const selectIsAutoSorting = ({blueprint}) =>
  [BLUEPRINT_TYPE_DATE, BLUEPRINT_TYPE_DATETIME, BLUEPRINT_TYPE_DATETIME_WITH_QUANTITY].includes(
    blueprint?.blueprint_type
  );
export const selectSignupCommentMaxLength = ({blueprint}) => {
  const comment = blueprint.field_schema.find(
    (s) => s.name === 'comment' && s.scope === SCOPE_USER
  );
  return comment?.max_chars;
};
export const selectDefaultThemeId = ({blueprint}) => blueprint.theme_id;
