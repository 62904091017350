import {useMemo} from 'react';
import {isAllDay} from '~/SignUpSheets/utils/misc';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {AddToCalendar} from '~/common/_pb_components/molecules/AddToCalendar';
import {Product} from '~/SignUpSheets/View/routes/VolunteerConfirm/components/SignUpContainer/Product/Product';
import {BuyFromSuggestion} from '~/SignUpSheets/View/routes/VolunteerConfirm/components/SignUpContainer/BuyFromSuggestion/BuyFromSuggestion';
import {toTitleCase} from '~/common/fabric/CreateDesign/components/CardEditor/actions/TextFormat/components/Casing/Casing';
import {Comment} from '~/SignUpSheets/View/routes/VolunteerConfirm/components/SignUpContainer/Comment/Comment';
import {UnknownUrl} from '~/SignUpSheets/View/routes/VolunteerConfirm/components/SignUpContainer/UnknownUrl/UnknownUrl';
import {
  isBuyFromSuggestion,
  isProduct,
  isUnknownUrl,
} from '~/SignUpSheets/View/routes/VolunteerConfirm/components/SignUpContainer/utils/utils';

import './SignUpItem.sass';

export const SignUpItem = ({
  comment,
  title,
  description,
  startTime,
  endTime,
  calendarLinks,
  count,
  product = {},
}) => {
  const isProductMemo = useMemo(() => isProduct(product), [product]);
  const isBuyFromSuggestionMemo = useMemo(() => isBuyFromSuggestion(product), [product]);
  const isUnknownUrlMemo = useMemo(() => isUnknownUrl(product), [product]);

  return (
    <div className="signup-day-item">
      <div className="signup-day-item__info">
        <div className="signup-day-item__info__title">
          <div>
            {!isAllDay(startTime, endTime) && (
              <Typography variant="list1" className="signup-day-item__info__title" semibold>
                {[startTime, endTime].join(' - ')}
              </Typography>
            )}

            {title && (
              <Typography variant="list1" className="signup-day-item__info__subtitle" semibold>
                {title}
              </Typography>
            )}
          </div>

          <AddToCalendar
            data-qa-id="add-to-calendar-link"
            isButton
            googleCalUrl={calendarLinks.google_cal_url}
            iCalUrl={calendarLinks.ical_url}
            outlookUrl={calendarLinks.outlook_cal_url}
            buttonSize="small"
            className="signup-day-item__add-to-calendar"
          />
        </div>

        {description && (
          <Typography variant="paragraph4" color="pebble">
            {description}
          </Typography>
        )}

        {isProductMemo && (
          <Product product={product} count={count} comment={comment} description={description} />
        )}

        {isBuyFromSuggestionMemo && <BuyFromSuggestion product={product} />}

        {isUnknownUrlMemo && (
          <UnknownUrl url={product.affiliate_url} displayText={toTitleCase(product.display_text)} />
        )}

        {!isProductMemo && (
          <>
            <Comment comment={comment} />

            <Typography variant="list2" size="small" className="signup-day-item__info__quantity">
              Signups: {count}
            </Typography>
          </>
        )}
      </div>
    </div>
  );
};
