export const DEFAULT_CARD_ZOOM = 0.5;
export const DEFAULT_WIDTH_DESKTOP = 500;
export const DEFAULT_HEIGHT_DESKTOP = 680;
export const DEFAULT_WIDTH_MW = 265;
export const DEFAULT_HEIGHT_MW = 360;
export const DEFAULT_WIDTH_MINIFIED = 132;
export const DEFAULT_HEIGHT_MINIFIED = 180;

export const Sections = {
  UPLOAD_PHOTO: 'photo_upload',
  CARD_DESIGN: 'card_design',
  ENVELOPE_LINER: 'enveloper_line',
  STAMP: 'stamp',
  BACKGROUND: 'background',
};

export const menuMappingKeys = {
  KEYBOARD: 'KEYBOARD',
  FONT_STYLE: 'FONT_STYLE',
  FONT_SIZE: 'FONT_SIZE',
  TEXT_FORMAT: 'TEXT_FORMAT',
  SPACING: 'SPACING',
  COLOR: 'COLOR',
  UYO: 'UYO',
};

export const RECOMMENDED_FONTS = [
  {
    family: 'BrandonGrotesqueMedium',
    image: '',
    name: 'Brandon Grotesque Medium',
    style: 'normal',
    type: 'sans-serif',
    url: '/assets/fonts/BrandonGrotesqueMedium.css',
    weight: 'normal',
  },
  {
    family: 'Vollkorn',
    image: '',
    name: 'Vollkorn Regular',
    style: 'normal',
    type: 'serif',
    url: 'https://fonts.googleapis.com/css2?family=Vollkorn&display=swap',
    weight: '400',
  },
  {
    family: 'la-bohemienne',
    image: '',
    name: 'La Bohemienne',
    style: 'normal',
    type: 'script',
    url: '',
    weight: '400',
  },
];

export const textColorOptions = [
  '#F6C3C7',
  '#FFEDC7',
  '#FBF8C7',
  '#D8EAD0',
  '#C6E0F7',
  '#E1C3DF',
  '#F2BFDC',
  '#F18482',
  '#FDD47B',
  '#F9EF81',
  '#AAD29F',
  '#84C4EC',
  '#A776B4',
  '#DF7DB3',
  '#E94342',
  '#F5962F',
  '#FAEA30',
  '#7BBC62',
  '#4C88CB',
  '#854B98',
  '#D51E86',
  '#E51C22',
  '#EF7E17',
  '#F9E415',
  '#5FB451',
  '#3170B7',
  '#744295',
  '#D0026E',
];
