export const LocationIcon = (props) => {
  const {color, width = 24, height = 32, className = '', ratio = 1} = props;

  const style = {
    height: `${ratio * height}px`,
    width: `${ratio * width}px`,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      className={className}
      style={style}
      fill={color}
    >
      <path
        d="M11.828 4.85278C7.98205 4.85278 4.85303 7.98168 4.85303 11.8276C4.85303 15.6735 7.98205 18.8024 11.828 18.8024C15.6739 18.8024 18.8029 15.6735 18.8029 11.8276C18.8029 7.98168 15.6739 4.85278 11.828 4.85278ZM11.828 16.9262C9.01651 16.9262 6.72909 14.639 6.72909 11.8275C6.72909 9.01602 9.01651 6.72885 11.828 6.72885C14.6394 6.72885 16.9269 9.01614 16.9269 11.8276C16.9269 14.6391 14.6396 16.9262 11.828 16.9262Z"
        fill="#6A6E71"
      />
      <path
        d="M11.8274 0C5.30576 0 0 5.30576 0 11.8274C0 15.5886 3.15429 21.1921 5.03448 24.1975C6.27155 26.1747 7.60093 28.0565 8.7776 29.4961C10.6663 31.8066 11.3423 32 11.8276 32C12.3201 32 12.9583 31.8065 14.8412 29.4947C16.0204 28.0465 17.3505 26.1658 18.5863 24.199C20.4793 21.1862 23.655 15.5727 23.655 11.8273C23.655 5.30576 18.3493 0 11.8274 0ZM17.2857 22.7383C14.8598 26.6759 12.6327 29.3366 11.8163 30.0159C10.9957 29.3575 8.80474 26.76 6.34497 22.7506C3.50499 18.1211 1.87619 14.1398 1.87619 11.8274C1.87606 6.34022 6.34022 1.87606 11.8274 1.87606C17.3147 1.87606 21.7788 6.34022 21.7788 11.8274C21.779 14.1265 20.1412 18.1033 17.2857 22.7383Z"
        fill="#6A6E71"
      />
    </svg>
  );
};
