import moment from 'moment-timezone';
import {getTimezoneAbbreviation} from '~/SignUpSheets/components/TimezoneSelect/TimezoneSelect';

const userTimezone = moment.tz.guess();

const isSameDay = (date1, date2, timezone) =>
  moment.tz(date1, timezone).isSame(moment.tz(date2, timezone), 'day');

const dateTimeFormat = (date, abbr, timezone, showTimes) => {
  const format = showTimes ? 'llll' : 'ddd, LL';
  return `${moment.tz(date, timezone).format(format)} ${abbr || ''}`.trim();
};

export const formatDate = (start, end, timezone, showTimes) => {
  if (!start) return null;

  const abbr = timezone && timezone !== userTimezone ? getTimezoneAbbreviation(timezone) : '';
  const startFormatted = dateTimeFormat(start, abbr, timezone, showTimes);

  if (!end) return startFormatted;

  const sameDay = isSameDay(start, end, timezone);

  if (sameDay) {
    const dateLine = moment.tz(start, timezone).format('ddd, ll');
    const startTime = moment.tz(start, timezone).format('LT');
    const endTime = moment.tz(end, timezone).format('LT');

    const timeLine = showTimes
      ? `${startTime}${endTime ? ` - ${endTime}` : ''} ${abbr}`.trim()
      : null;

    return (
      <>
        {dateLine}
        {timeLine && (
          <>
            <br />
            {timeLine}
          </>
        )}
      </>
    );
  }

  const endFormatted = dateTimeFormat(end, abbr, timezone, showTimes);
  return (
    <>
      {startFormatted} -<br />
      {endFormatted}
    </>
  );
};
