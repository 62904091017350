import React from 'react';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export const Outlook = (props) => {
  const {className = '', ratio = 1} = props;

  // default dimensions
  const width = 32;
  const height = 32;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 5.03501V26.6991L18.4805 30.1547V1.84521L2 5.03501ZM10.303 20.9053C5.06388 20.5675 5.60348 11.1239 10.4253 11.0489C15.5908 11.3903 15.0841 20.83 10.303 20.9053ZM10.3785 12.8979C7.61844 13.0895 7.74199 19.0024 10.336 19.05C13.0842 18.8728 12.9228 12.9438 10.3785 12.8979ZM21.2887 16.664C21.5375 16.8468 21.8373 16.664 21.8373 16.664C21.5385 16.8468 30 11.2264 30 11.2264V21.404C30 22.512 29.2908 22.9765 28.4933 22.9765H19.5215L19.5221 15.4508L21.2887 16.664ZM19.5225 7.84736V13.3848L21.4577 14.6033C21.5088 14.6183 21.6193 14.6193 21.6704 14.6033L29.9989 8.98825C29.9989 8.32371 29.3791 7.84736 29.0292 7.84736H19.5225Z"
        fill={charcoal}
      />
    </svg>
  );
};
