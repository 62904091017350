import {useState, useMemo, useRef, createContext, useEffect} from 'react';
import cx from 'classnames';
import {Button} from '~/common/_pb_components/atoms/Button';

export const DropdownContext = createContext(null);

const useDropdown = (ref, onClickOut) => {
  useEffect(() => {
    const clickOut = ({target}) => {
      if (!ref?.current?.contains(target)) onClickOut();
    };

    document.addEventListener('mousedown', clickOut);
    return () => document.removeEventListener('mousedown', clickOut);
  }, [ref]);

  return [onClickOut];
};

// Please note: the Dropdown component already wraps the
// trigger in a Button, do not pass a Button in the trigger!
export const Dropdown = ({
  open: isOpen,
  children,
  trigger,
  style,
  content,
  withPadding = false,
  buttonVariant = 'transparent',
  buttonClassName,
  offsetLeft = true,
  setOpen,
  disabled = false,
  buttonQaIdModifier = '',
  'data-qa-id': dataQaId = 'dropdown',
  'data-qa-value': dataQaValue,
}) => {
  const [open, toggle] = useState(isOpen || false);
  const ref = useRef(null);
  const toggleContext = useMemo(() => ({toggle}), [open, toggle]);

  useDropdown(ref, () => toggle(false));

  useEffect(() => {
    toggle(isOpen);
  }, [isOpen]);

  return (
    <DropdownContext.Provider value={toggleContext}>
      <div
        ref={ref}
        style={{position: 'relative', ...style}}
        data-qa-id={dataQaId}
        data-qa-value={dataQaValue}
      >
        <Button
          onClick={() => {
            toggle(!open);
            if (setOpen) {
              setOpen(!open);
            }
          }}
          disabled={disabled}
          data-qa-id={`dropdown__trigger${buttonQaIdModifier ? `--${buttonQaIdModifier}` : ''}`}
          variant={buttonVariant}
          className={buttonClassName}
        >
          {children?.[0] || trigger}
        </Button>
        {open && (children?.[1] || content) && (
          <div
            className={cx('dropdown__menu', {
              'dropdown__menu--padding': withPadding,
              'dropdown__menu--offset-left': offsetLeft,
            })}
          >
            {children?.[1] || content}
          </div>
        )}
      </div>
    </DropdownContext.Provider>
  );
};
