import {createContext, useContext} from 'react';

const storesContext = createContext();

const useStore = () => {
  const store = useContext(storesContext);
  if (!store) {
    throw new Error('useStore must be used within a StoreProvider.');
  }
  return store;
};

const StoreProvider = ({children}) => {
  const store = useLocalStore(createStores);
  return <storesContext.Provider value={store}>{children}</storesContext.Provider>;
};

export {useStore, StoreProvider};
