import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {loginModalPromise} from '~/common/fabric/Card/components/LoginModal/utils/loginModalPromise';
import {userIsLoggedIn} from '~/common/fabric/User/reducers/user';

export const useRequestLogin = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(userIsLoggedIn);

  const requestLogin = useCallback(async () => {
    if (!loggedIn) {
      return await loginModalPromise(dispatch)
        .then(() => true)
        .catch(() => false);
    }

    return loggedIn;
  }, [loggedIn, dispatch]);

  return {loggedIn, requestLogin};
};
