import {useMemo} from 'react';
import {formatDateShort} from '~/SignUpSheets/utils/misc';
import {Product} from '~/SignUpSheets/View/routes/VolunteerConfirm/components/SignUpContainer/Product/Product';
import {BuyFromSuggestion} from '~/SignUpSheets/View/routes/VolunteerConfirm/components/SignUpContainer/BuyFromSuggestion/BuyFromSuggestion';
import {toTitleCase} from '~/common/fabric/CreateDesign/components/CardEditor/actions/TextFormat/components/Casing/Casing';
import {Comment} from '~/SignUpSheets/View/routes/VolunteerConfirm/components/SignUpContainer/Comment/Comment';
import {Header} from '~/SignUpSheets/View/routes/VolunteerConfirm/components/SignUpContainer/Header/Header';
import {UnknownUrl} from '~/SignUpSheets/View/routes/VolunteerConfirm/components/SignUpContainer/UnknownUrl/UnknownUrl';
import {
  isBuyFromSuggestion,
  isProduct,
  isUnknownUrl,
} from '~/SignUpSheets/View/routes/VolunteerConfirm/components/SignUpContainer/utils/utils';

import './SignUpItem.sass';

export const SignUpItem = ({item, date, description, count, comment = '', product = {}}) => {
  const isProductMemo = useMemo(() => isProduct(product), [product]);
  const isBuyFromSuggestionMemo = useMemo(() => isBuyFromSuggestion(product), [product]);
  const isUnknownUrlMemo = useMemo(() => isUnknownUrl(product), [product]);

  return (
    <div className="signup-item">
      <Header
        title={item || formatDateShort(date)}
        subtitle={!isProductMemo && `Signups: ${count}`}
        description={description}
      />

      {isProductMemo && (
        <Product
          product={product}
          count={count}
          comment={comment}
          className="signup-item__product"
        />
      )}

      {isBuyFromSuggestionMemo && (
        <BuyFromSuggestion product={product} className="signup-item__buy-from-suggestion" />
      )}

      {isUnknownUrlMemo && (
        <UnknownUrl
          url={product.affiliate_url}
          displayText={toTitleCase(product.display_text)}
          className="signup-item__unknown_url"
        />
      )}

      {!isProductMemo && <Comment comment={comment} className="signup-item__comment" />}
    </div>
  );
};
