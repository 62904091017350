import cx from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import {useMatchQuery} from '~/common/hooks/useMatchQuery';
import {largeAndUp} from '~sass/pb_styleguide/base/_exports.sass';

export const LoadingOverlay = ({
  show,
  loadingMessage,
  className,
  overlayClassName = 'loading--overlay',
}) => {
  const {matches: isLarge} = useMatchQuery(`(min-width: ${largeAndUp})`);

  return show ? (
    <div className={overlayClassName}>
      <div
        className={cx({
          className,
          button: !className,
          primary: !className,
          large: isLarge,
          small: !isLarge,
        })}
      >
        <span className="evt-spinner inline" />
        <p>{loadingMessage}</p>
      </div>
    </div>
  ) : null;
};

LoadingOverlay.propTypes = {
  overlayClassName: PropTypes.string,
  className: PropTypes.string,
  loadingMessage: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
};
