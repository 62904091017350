import {useSelector} from 'react-redux';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {useIsLargishAndUp} from '~/common/hooks/useMatchQuery';
import {selectDetailsHeader} from '~/SignUpSheets/reducers/themes/selectors';

export const DetailsHeader = ({title}) => {
  const isLargishAndUp = useIsLargishAndUp();
  const bg = useSelector((s) => selectDetailsHeader(s, {isDesktop: isLargishAndUp}));
  const style = bg ? {backgroundImage: `url("https:${bg}")`} : {};

  return (
    <div className="signup-sheet__view-details__header" style={style}>
      {isLargishAndUp && (
        <Typography variant="header1" as="h1" className="signup-sheet__view-details__title">
          {title}
        </Typography>
      )}
    </div>
  );
};
