import cx from 'classnames';
import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {userLoggedIn, hideLoginModal} from '~/common/components/LoginModal/reducer/actions';
import {LoginWrapper} from '~/LoginModal/LoginWrapper';

export const isGuamWebviewOrLater = () =>
  !!window.webkit?.messageHandlers?.pendingUpgradeToPremium || // ios
  !!window.webview?.pendingUpgradeToPremium; // android

const webViewSetShouldUserLogIn = (value) => {
  try {
    window.webkit?.messageHandlers?.setShouldUserLogIn?.postMessage(value);
  } catch (e) {
    // eslint-disable-next-line no-alert
    alert(`An error occurred in webViewSetShouldUserLogIn(ios): ${e}`);
  }
};

export const LoginModalWrapper = () => {
  const dispatch = useDispatch();
  const showModal = useSelector((state) => state.loginModal.showLoginModal);

  useEffect(() => {
    if (isGuamWebviewOrLater() && showModal) {
      webViewSetShouldUserLogIn(true);
    }
  }, [showModal]);

  const onSignUpSuccess = useCallback(
    (userId, fullName, subscriptionPlanName, email, avatarDisk, imgUrl, initials, firstName) =>
      dispatch(
        userLoggedIn(
          userId,
          fullName,
          subscriptionPlanName,
          email,
          avatarDisk,
          imgUrl,
          initials,
          firstName
        )
      ),
    [dispatch]
  );

  const onLoginSuccess = useCallback(
    (userId, fullName, subscriptionPlanName, email, avatarDisk, imgUrl, initials, firstName) =>
      dispatch(
        userLoggedIn(
          userId,
          fullName,
          subscriptionPlanName,
          email,
          avatarDisk,
          imgUrl,
          initials,
          firstName
        )
      ),
    [dispatch]
  );

  return (
    <>
      <div
        className={cx('login-modal-background', {
          'bg-visible': showModal,
          'bg-hidden': !showModal,
        })}
      />
      <div className={cx('meganav-auth-slideout', {open: showModal})}>
        <LoginWrapper
          onSignupSuccess={onSignUpSuccess}
          onLoginSuccess={onLoginSuccess}
          onCloser={() => dispatch(hideLoginModal())}
        />
      </div>
    </>
  );
};
