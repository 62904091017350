import {rehype} from 'rehype';
import {remark} from 'remark';
import rehypeRemark from 'rehype-remark';
import remarkHtml from 'remark-html';
import remarkStringify from 'remark-stringify';

export const markdown2Html = (md = '') => {
  const html = remark().use(remarkHtml).processSync(md);
  return String(html);
};

export const html2Markdown = (html = '') => {
  const md = rehype().use(rehypeRemark, {newlines: true}).use(remarkStringify).processSync(html);
  return String(md);
};
