import React, {useCallback, useContext, useEffect, useState} from 'react';
import {TextFormatCasingBase} from '~/common/fabric/CreateDesign/components/CardEditor/actions/TextFormat/components/Casing/TextFormatCasingBase/TextFormatCasingBase';
import {FabricContext} from '~/common/fabric/context/FabricContext';

export const toTitleCase = (text) => {
  if (!text) return null;

  return text
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const toUpperCase = (text) => text.toUpperCase();
const toLowerCase = (text) => text.toLowerCase();

const actions = {
  capitalize: 'capitalize',
  upper: 'upper',
  lower: 'lower',
};

const letterCasingMapping = {
  [actions.capitalize]: toTitleCase,
  [actions.upper]: toUpperCase,
  [actions.lower]: toLowerCase,
};

export const Casing = ({className}) => {
  const {canvas} = useContext(FabricContext);
  const activeObjects = canvas?.getActiveObjects();

  const [currentLetterCase, setCurrentLetterCase] = useState(null);

  useEffect(() => {
    const updateLetterCase = () =>
      setCurrentLetterCase(canvas?.getActiveObjects()?.[0]?.letterCasing);

    updateLetterCase();
    canvas?.on('object:reset', updateLetterCase);

    return () => {
      canvas?.off('object:reset', updateLetterCase);
    };
  }, [canvas]);

  const onClickHandler = useCallback(
    (operation) => {
      for (const obj of activeObjects ?? []) {
        if (operation) {
          obj.text = letterCasingMapping[operation](obj.text);
        } else {
          // eslint-disable-next-line no-underscore-dangle
          obj.text = obj._originalText;
        }

        obj.letterCasing = operation;

        setCurrentLetterCase(operation);
      }

      canvas.requestRenderAll();
      canvas.fire('object:modified');
    },
    [canvas, activeObjects]
  );

  return (
    <div className={className}>
      <TextFormatCasingBase
        value={actions.upper}
        onClick={onClickHandler}
        active={currentLetterCase === actions.upper}
        dataQaId="step1-textformat-uppercase"
        label="AA"
      />
      <TextFormatCasingBase
        value={actions.lower}
        onClick={onClickHandler}
        active={currentLetterCase === actions.lower}
        dataQaId="step1-textformat-lowercase"
        label="aa"
      />
      <TextFormatCasingBase
        value={actions.capitalize}
        onClick={onClickHandler}
        active={currentLetterCase === actions.capitalize}
        dataQaId="step1-textformat-titlecase"
        label="Aa"
      />
    </div>
  );
};
