import {useSelector} from 'react-redux';
import cx from 'classnames';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {useIsLargishAndUp} from '~/common/hooks/useMatchQuery';
import {Divider} from '~/common/_pb_components/atoms/Divider';
import {selectTitle, selectVolunteerMessage} from '~/SignUpSheets/reducers/sheet/selectors';
import {selectBlueprintId} from '~/SignUpSheets/reducers/blueprint/selectors';
import {DetailsOrganizerNote} from './DetailsOrganizerNote';
import {DetailsHeader} from './DetailsHeader';
import {DetailsDate} from './DetailsDate';
import {DetailsLocation} from './DetailsLocation';
import {DetailsOrganizer} from './DetailsOrganizer';
import {usePreviewValue} from './hooks/usePreviewValue';
import './ViewDetails.sass';

export const ViewDetails = () => {
  const title = usePreviewValue(selectTitle, 'Sheet title');
  const organizerNote = usePreviewValue(selectVolunteerMessage, 'Organizer note');
  const blueprintId = useSelector(selectBlueprintId);
  const isLargishAndUp = useIsLargishAndUp();

  return (
    <div
      className={cx(
        'page-section',
        'signup-sheet__view-details',
        `signup-sheet__view-details-${blueprintId}`
      )}
    >
      <DetailsHeader title={title} />

      <div className="signup-sheet__view-details__content">
        {isLargishAndUp && (
          <div className="signup-sheet__view-details__content__desktop">
            <DetailsDate />
            <DetailsLocation />
            <DetailsOrganizer />
          </div>
        )}

        {!isLargishAndUp && (
          <>
            <Typography variant="header2" as="h1" className="signup-sheet__view-details__title">
              {title}
            </Typography>

            <DetailsDate />

            <div className="signup-sheet__view-details__content__divider__wrapper">
              <Divider className="signup-sheet__view-details__content__divider" />
            </div>

            <div className="signup-sheet__view-details__content__location__organizer">
              <DetailsLocation />
              <DetailsOrganizer />
            </div>
          </>
        )}

        <div className="signup-sheet__view-details__content__divider__wrapper">
          <Divider className="signup-sheet__view-details__content__divider" />
        </div>

        <DetailsOrganizerNote organizerNote={organizerNote} />
      </div>
    </div>
  );
};
