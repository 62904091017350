import React, {useCallback, useContext} from 'react';
import {Button} from '~/common/_pb_components/atoms/Button';
import {EventCreateContext} from '~/CreateFabricInvitation/routes/EventCreateDesign/EventCreateContext';

import './ResetStyle.sass';
import {menuMappingKeys} from '~/CreateFabricInvitation/routes/EventCreateDesign/constants';
import {useSelector} from 'react-redux';
import {selectEditorDisabled} from '~/CreateFabricInvitation/reducers/fabricDesign/selectors';

const fabricToStyleMapping = {
  [menuMappingKeys.FONT_STYLE]: ['fontFamily', 'fontStyle', 'fontWeight'],
  [menuMappingKeys.FONT_SIZE]: ['fontSize'],
  [menuMappingKeys.TEXT_FORMAT]: ['letterCasing', 'textAlign'],
  [menuMappingKeys.SPACING]: ['charSpacing', 'lineHeight'],
  [menuMappingKeys.COLOR]: ['fill'],
};

const toSentenceCase = (text) => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
export const ResetStyle = ({label = 'Reset All', style = 'all', onResetClick, dataQaId}) => {
  const {canvas, activeObjects} = useContext(EventCreateContext);
  const editorDisabled = useSelector(selectEditorDisabled);

  const resetElement =
    onResetClick ||
    useCallback(
      (currElement) => {
        let newObject;
        if (style === 'all') {
          newObject = currElement._originalObject;
          const {_originalObject, ...originalObject} = currElement._originalObject;
          newObject._originalObject = originalObject;
          newObject.text = currElement?.text;
        } else {
          newObject = fabricToStyleMapping[style].reduce(
            (prevValue, currValue) => ({
              ...prevValue,
              [currValue]: currElement._originalObject[currValue],
            }),
            {}
          );
        }

        newObject.text = toSentenceCase(currElement?.text || '');

        currElement.set(newObject);
      },
      [canvas]
    );

  const onClickHandler = useCallback(() => {
    activeObjects.forEach(resetElement);
    canvas.requestRenderAll();
    canvas.fire('object:modified');
    canvas.fire('object:reset');
  }, [canvas, activeObjects, resetElement]);

  return (
    <Button
      onClick={onClickHandler}
      data-qa-id={dataQaId}
      variant="link"
      size="small"
      className="reset_all__button"
      disabled={editorDisabled}
    >
      {label}
    </Button>
  );
};
