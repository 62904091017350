import {createContext, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {selectIsInEditMode} from '~/SignUpSheets/reducers/sheet/selectors';

const defaultContextValue = {
  header: {
    title: '',
    setTitle: () => {},
  },
  flow: 'edit',
  prevStateSheet: undefined,
  setPrevStateSheet: () => {},
};

export const SignUpSheetContext = createContext(defaultContextValue);

export const SignUpSheetContextParent = ({children}) => {
  const isInEditMode = useSelector(selectIsInEditMode);
  const [title, setTitle] = useState('');
  const [prevStateSheet, setPrevStateSheet] = useState();
  const [flow, setFlow] = useState('edit'); // Initializing to Edit since in the edit flow, header has no controls

  useEffect(() => {
    if (window.location.pathname.startsWith('/signup-sheet/')) {
      setFlow('view');
    } else {
      setFlow(isInEditMode ? 'edit' : 'create');
    }
  }, [isInEditMode]);

  useEffect(() => {
    if (flow === 'view') {
      setTitle('View sheet');
    } else {
      setTitle(isInEditMode ? 'Edit sheet' : 'SignUp Sheet');
    }
  }, [isInEditMode, flow]);

  /* eslint-disable react/jsx-no-constructed-context-values */
  return (
    <SignUpSheetContext.Provider
      value={{
        header: {
          title,
          setTitle,
        },
        flow,
        prevStateSheet,
        setPrevStateSheet,
      }}
    >
      {children}
    </SignUpSheetContext.Provider>
  );
};
