import {PLAN_NAME_PARTY_PASS, PLAN_NAME_PRO} from '~/common/fabric/routes/EventReview/constants';

export const selectUser = (state) => state.user;
export const userIsLoggedIn = (state) => !!state.user.userId;
export const selectUserName = (state) => state.user.userName;
export const getLoggedUserId = ({user}) => user.userId;
export const selectUserEmail = (state) => state.user.email;
export const selectUserSha256 = (state) => state.user.emailSha256;
export const selectUserFirstName = (state) => state.user.firstName;
export const selectUserPhoneNumber = (state) => state?.user?.phoneNumber || '';
export const selectVolunteerEmail = (state) => state?.user?.volunteerEmail;
export const selectVolunteerName = (state) => state?.user?.volunteerName;
export const selectIsProOrPartyPass = ({user}) =>
  user.subscriptionPlanName === PLAN_NAME_PRO || user.subscriptionPlanName === PLAN_NAME_PARTY_PASS;
export const selectSubscriptionPlanName = ({user}) => user?.subscriptionPlanName;
export const selectSubscriptionEndDate = ({user}) => user?.subscriptionEndDate;
export const selectSubscriptionStatus = ({user}) => user?.subscriptionStatus;
