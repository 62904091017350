export const selectFabricArt = ({fabricDesign}) => fabricDesign.fabricArt;

export const selectFabricArtBackgroundImage = ({fabricDesign}) =>
  fabricDesign.fabricArt?.backgroundImage;

export const selectEnvelopeStickers = ({fabricDesign}) => fabricDesign.envelopeStickers;

export const selectOriginalFabricArt = ({fabricDesign}) => fabricDesign.originalFabricArt;

export const selectBackgroundId = ({fabricDesign}) => fabricDesign.backgroundId;

export const selectBackgroundColor = ({fabricDesign}) => fabricDesign.backgroundColor;

export const selectStampId = ({fabricDesign}) => fabricDesign.stampId;

export const selectEnvelopeId = ({fabricDesign}) => fabricDesign.envelopeId || 'wht01';

export const selectLinerId = ({fabricDesign}) => fabricDesign.linerId;

export const selectCurrentSection = ({fabricDesign}) => fabricDesign.currentSection;

export const selectLoaded = ({fabricDesign}) => fabricDesign?.loaded ?? false;

export const selectTouched = ({fabricDesign}) => fabricDesign.touched;

export const selectPristine = ({fabricDesign}) => fabricDesign.pristine;

export const selectPristineUyo = ({fabricDesign}) => fabricDesign.pristineUyo;

export const selectPremiumFeaturesPristine = ({fabricDesign}) =>
  fabricDesign.premiumFeaturesPristine;

export const selectPremiumFeaturesWarning = ({fabricDesign}) => fabricDesign.premiumFeaturesWarning;

export const selectIgnorePremiumWarning = ({fabricDesign}) => fabricDesign.ignorePremiumWarning;

export const selectSavingStep = ({fabricDesign}) => fabricDesign.savingStep;

export const selectTextAreaOpen = ({fabricDesign}) => fabricDesign.textAreaOpen;

export const selectEditingMobile = ({fabricDesign}) => fabricDesign.editingMobile;
export const selectEditorDisabled = ({fabricDesign}) => fabricDesign.editorDisabled;

export const selectEditingUyoMobile = ({fabricDesign}) => fabricDesign.editingUyoMobile;

export const selectForceShowOnboardingModal = ({fabricDesign}) =>
  fabricDesign.forceShowOnboardingModal;

export const selectSelectedEnvelope = (state) => {
  const {envelopeId} = state.fabricDesign;
  if (!envelopeId) return undefined;
  for (const envelope of state.fabricTemplate.allEnvelopes)
    if (envelope.id === envelopeId) return envelope;
  return state.fabricTemplate.allEnvelopes[0];
};

export const selectSelectedStamp = (state) => {
  const {stampId} = state.fabricDesign;
  if (!stampId) return undefined;
  for (const stamp of state.fabricTemplate.allStamps) if (stamp.id === stampId) return stamp;
  return state.fabricTemplate.allStamps[0];
};

export const selectSelectedLiner = (state) => {
  const {linerId} = state.fabricDesign;
  if (!linerId) return undefined;
  for (const liner of state.fabricTemplate.allLiners) if (liner.id === linerId) return liner;
};

export const selectSelectedBackground = (state) => {
  const {backgroundId} = state.fabricDesign;
  if (!backgroundId) return undefined;
  for (const bg of state.fabricTemplate.allBackgrounds) if (bg.id === backgroundId) return bg;
};
