import PropTypes from 'prop-types';
import {useCallback, useContext, useEffect, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import isEqual from 'lodash/isEqual';
import {PB_Checkmark as CheckmarkIcon} from '~/common/svg/PB_Checkmark';
import {PB_LinkIcon as LinkIcon} from '~/common/svg/PB_LinkIcon';
import {MoreIcon} from '~/common/svg/MoreIcon';
import {
  selectGateUnlockedForSheet,
  selectIsInEditMode,
  selectIsOrganizer,
  selectLocalWishlists,
  selectSheetId,
  selectSignups,
  selectTitle,
  selectWishlistOption,
  selectWishlists,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {useUpdateSheet} from '~/SignUpSheets/hooks/useUpdateSheet';
import {getLoggedUserId} from '~/common/fabric/User/reducers/user';
import {Button} from '~/common/_pb_components/atoms/Button';
import {Spinner} from '~/common/_pb_components/atoms/Spinner';
import {useSheetActions} from '~/SignUpSheets/hooks/useSheetActions';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {Dropdown} from '~/common/_pb_components/molecules/Dropdown';
import {SignUpSheetContext} from '~/SignUpSheets/context/SignUpSheetContext';
import {emailSheetVolunteers} from '~/SignUpSheets/utils/misc';
import {ConfirmNotifyModal} from '~/SignUpSheets/Create/routes/Customize/components/Builder/Elements/ConfirmNotifyModal';

import {coin, ghost} from '~sass/pb_styleguide/base/_exports.sass';
import './HeaderActionButtons.sass';

const MenuItems = ({emailVolunteers, handleCancel}) => [
  <div className="dropdown__menu-item" key="email-volunteers">
    <Button
      data-qa-id="email-volunteers"
      variant="transparent"
      size="small"
      onClick={emailVolunteers}
    >
      <Typography variant="list1">Email volunteers</Typography>
    </Button>
  </div>,
  <div className="dropdown__menu-item" key="cancel-sheet">
    <Button data-qa-id="cancel-sheet" variant="transparent" size="small" onClick={handleCancel}>
      <Typography variant="list1">Cancel sheet</Typography>
    </Button>
  </div>,
];

export const HeaderActionButtons = ({handleEdit, handleShare, handleCancel, copied = false}) => {
  const {flow} = useContext(SignUpSheetContext);
  const {autoSave, isSavingSheet, isPublishingSheet, isSaveSuccess, isSaveError, onSave} =
    useSheetActions();
  const isOrganizer = useSelector(selectIsOrganizer);
  const signups = useSelector(selectSignups, shallowEqual);
  const sheetTitle = useSelector(selectTitle);
  const sheetId = useSelector(selectSheetId);
  const isInEditMode = useSelector(selectIsInEditMode);
  const wishlists = useSelector(selectWishlists, shallowEqual);
  const wishlistOption = useSelector(selectWishlistOption);
  const {updateSheetDetails} = useUpdateSheet();
  const isLoggedIn = useSelector(getLoggedUserId);
  const localWishlists = useSelector(selectLocalWishlists, shallowEqual);
  const showNotifyVolunteersModal = useSelector(selectGateUnlockedForSheet);
  const [triggerConfirmNotifyOnSave, setTriggerConfirmNotifyOnSave] = useState(false);
  const [showConfirmNotifyModal, setShowConfirmNotifyModal] = useState(false);
  const [notifyVolunteersOfChanges, setNotifyVolunteersOfChanges] = useState(null);

  const handleSaveOrPublish = useCallback(
    () =>
      new Promise((resolve) => {
        const usingWishlists = wishlistOption === 'my-wishlist';
        const hasEmptyWishlist = localWishlists.some((w) => w.trim() === '');
        if ((usingWishlists && hasEmptyWishlist) || !isEqual(wishlists, localWishlists)) {
          updateSheetDetails({
            payload: {
              has_unsaved_wishlists: true,
            },
          });
          resolve();
        } else {
          updateSheetDetails({
            payload: {
              has_unsaved_wishlists: false,
            },
            errorsToClear: [['wishlists']],
          });
          if (isInEditMode) {
            onSave(false)
              .then((result) => {
                if (result) {
                  window.location.href = `/signup-sheet/${sheetId}`;
                }
              })
              .catch(evite.error)
              .finally(resolve);
          } else {
            autoSave(true).catch(evite.error).finally(resolve);
          }
        }
      }),
    [autoSave, wishlistOption, localWishlists, onSave, updateSheetDetails, wishlists, isInEditMode]
  );

  const handleNotifyModalBeforeSave = useCallback(() => {
    // EVT-31532: Some finagling needed to allow error handling before notify modal
    if (isSaveError && triggerConfirmNotifyOnSave) {
      handleSaveOrPublish(); // to trigger validation modal
    }
    if (!isSaveError && triggerConfirmNotifyOnSave) {
      setShowConfirmNotifyModal(true);
    } else {
      handleSaveOrPublish();
    }
  }, [isSaveError, triggerConfirmNotifyOnSave, handleSaveOrPublish]);

  const handlePrimaryAction = useCallback(() => {
    setNotifyVolunteersOfChanges(true);
    setShowConfirmNotifyModal(false);
  }, []);

  const handleSecondaryAction = useCallback(() => {
    setNotifyVolunteersOfChanges(false);
    setShowConfirmNotifyModal(false);
  }, []);

  useEffect(() => {
    if (notifyVolunteersOfChanges !== undefined && notifyVolunteersOfChanges !== null) {
      updateSheetDetails({
        payload: {
          notify_volunteers: notifyVolunteersOfChanges,
        },
      });
      handleSaveOrPublish();
    }
  }, [notifyVolunteersOfChanges]);

  useEffect(() => {
    setTriggerConfirmNotifyOnSave(showNotifyVolunteersModal);
  }, [showNotifyVolunteersModal]);

  return (
    <div className="signup-sheet__header__buttons">
      {['create', 'edit'].includes(flow) ? (
        <>
          {!isInEditMode && (
            <Button
              data-qa-id="save-progress"
              className="signup-sheet__header__action-button"
              variant="secondary"
              onClick={() => autoSave()}
              disableProcessing
              disabled={isSavingSheet || isPublishingSheet}
              size="large"
            >
              Save progress
              <Spinner isLoading={isSavingSheet} isSuccess={isSaveSuccess} isError={isSaveError} />
            </Button>
          )}
          <Button
            data-qa-id={isInEditMode ? 'save-sheet-cta' : 'publish-sheet-cta'}
            variant="primary"
            fullWidth
            disableProcessing
            disabled={isSavingSheet || isPublishingSheet || !sheetId || !isLoggedIn}
            size="large"
            onClick={isInEditMode ? handleNotifyModalBeforeSave : handleSaveOrPublish}
            icon={
              !isInEditMode ? (
                <LinkIcon color={isSavingSheet || !sheetId || !isLoggedIn ? coin : ghost} />
              ) : null
            }
          >
            {isInEditMode ? 'Save changes' : 'Get Shareable Link'}
            <Spinner isLoading={isSavingSheet} isSuccess={isSaveSuccess} isError={isSaveError} />
          </Button>
        </>
      ) : null}
      {isOrganizer && flow === 'view' ? (
        <>
          <Button
            data-qa-id="get-shareable-link"
            className="signup-sheet__header__action-button shareable-link-button"
            variant="secondary"
            onClick={handleShare}
            icon={copied ? <CheckmarkIcon ratio={0.5} /> : <LinkIcon ratio={0.75} />}
            disableProcessing
          >
            <Typography variant="paragraph3" semibold>
              {copied ? 'Copied!' : 'Copy shareable link'}
            </Typography>
          </Button>
          <Button
            data-qa-id="edit-sheet"
            className="signup-sheet__header__action-button"
            variant="primary"
            onClick={handleEdit}
            disableProcessing
          >
            Edit sheet
          </Button>
          <Dropdown
            buttonQaIdModifier="header-more-trigger"
            buttonVariant="secondary"
            buttonClassName="signup-sheet__header__more-menu"
            trigger={<MoreIcon ratio={0.5} color="charcoal" />}
            content={
              <MenuItems
                emailVolunteers={() => emailSheetVolunteers({signups, title: sheetTitle})}
                handleCancel={handleCancel}
              />
            }
          />
        </>
      ) : null}
      {showConfirmNotifyModal && (
        <ConfirmNotifyModal
          onPrimaryAction={handlePrimaryAction}
          onSecondaryAction={handleSecondaryAction}
          onClose={handleSecondaryAction}
        />
      )}
    </div>
  );
};

HeaderActionButtons.propTypes = {
  handleEdit: PropTypes.func.isRequired,
  handleShare: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  copied: PropTypes.bool,
};
