import cx from 'classnames';
import {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button} from '~/common/_pb_components/atoms/Button';
import {PB_Info as InfoIcon} from '~/common/svg/PB_Info';
import {PB_CloseLine as CloseLine} from '~/common/svg/PB_CloseLine';
import {charcoal, popoverColor, popoverTextColor} from '~sass/pb_styleguide/base/_exports.sass';

export const PopOver = ({
  children,
  id,
  showOnce,
  showCloseButton,
  closeButtonCallback,
  cookieKey,
  position = 'bottom',
  buttonId = 'popover__hover-trigger',
  buttonContent = <InfoIcon />,
  buttonClassName,
  bkgColor = popoverColor,
  textColor = popoverTextColor,
  contentClassName,
  arrowClassName,
  className,
  closeOnOutsideClick = false,
  'data-qa-id': qaId,
}) => {
  const [show, setShow] = useState(false);
  const popoverRef = useRef(null);

  useEffect(() => {
    if (!closeOnOutsideClick || !show) return undefined;
    const container = document.querySelector('main[id="main_content"]');
    const detectClickOutside = (e) => {
      const closeId = 'popover-close-button';
      const popOverButton = buttonId ? document.getElementById(buttonId) : null;
      const popOverCloseButton = document.getElementById(closeId) ?? null;

      if (
        popoverRef.current &&
        e.target !== popOverButton &&
        e.target !== popoverRef.current &&
        e.target !== popOverCloseButton
      ) {
        setShow(false);
      }
    };
    container.addEventListener('click', detectClickOutside);
    return () => {
      container.removeEventListener('click', detectClickOutside);
    };
  }, [show, closeOnOutsideClick]);

  const trigger = useCallback((e) => {
    e.preventDefault();
    setShow((on) => !on);
  }, []);

  useEffect(() => {
    if (showOnce) {
      const newCookieVal = window.localStorage.getItem(`${cookieKey}`); // if it equals the ButtonId sliced
      if (newCookieVal === null || buttonId.slice(buttonId.length - 1) === newCookieVal) {
        setShow(true);
      }
    }
  }, [showOnce, cookieKey, buttonId]);

  const onDismiss = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      closeButtonCallback();
      setShow(false);
    },
    [closeButtonCallback]
  );

  return (
    <div
      className={cx('popover-container', {[className]: className})}
      data-qa-id={`${qaId}_container`}
      role="tooltip"
      id={id}
    >
      <Button
        onClick={trigger}
        variant="transparent"
        data-qa-id="disabledInputTooltip-button"
        aria-describedby={id}
        id={buttonId}
        className={buttonClassName}
        type="button"
      >
        {buttonContent}
      </Button>

      {show && (
        <div
          className={cx(`pb-popover popover-${position}`, {[contentClassName]: contentClassName})}
          style={{backgroundColor: bkgColor, color: textColor}}
          data-qa-id={qaId}
          ref={popoverRef}
        >
          <div
            className={cx('popover-arrow', {[arrowClassName]: arrowClassName})}
            style={{backgroundColor: bkgColor}}
          />
          {showCloseButton && closeButtonCallback && (
            <Button
              onClick={onDismiss}
              variant="transparent"
              data-qa-id="popover-close-button"
              id="popover-close-button"
              className="popover-close-button"
              aria-label="Close"
              type="button"
            >
              <CloseLine color={charcoal} ratio={0.75} />
            </Button>
          )}
          {children}
        </div>
      )}
    </div>
  );
};

PopOver.propTypes = {
  position: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  id: PropTypes.string.isRequired,
  buttonId: PropTypes.string,
  buttonContent: PropTypes.node,
  buttonClassName: PropTypes.string,
  bkgColor: PropTypes.string,
  textColor: PropTypes.string,
  contentClassName: PropTypes.string,
  arrowClassName: PropTypes.string,
  'data-qa-id': PropTypes.string.isRequired,
  className: PropTypes.string,
  closeOnOutsideClick: PropTypes.bool,
};
