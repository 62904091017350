import {useRef, useCallback, useState, useMemo} from 'react';

export const useVideoControl = () => {
  const videoRef = useRef(null);
  const [visible, setVisible] = useState(true);

  const play = useCallback(() => {
    setVisible(true); // Ensure video is visible when playing
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, [videoRef]);

  const pause = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  }, [videoRef]);

  const restart = useCallback(() => {
    setVisible(true); // Ensure video is visible when restarting
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    }
  }, [videoRef]);

  const stop = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  }, [videoRef]);

  const hide = useCallback(() => {
    setVisible(false);
  }, []);

  const show = useCallback(() => {
    setVisible(true);
  }, []);

  // Memoize the returned object to ensure a stable reference
  return useMemo(
    () => ({
      videoRef,
      play,
      pause,
      restart,
      stop,
      hide,
      show,
      visible,
    }),
    [videoRef, play, pause, restart, stop, hide, show, visible]
  );
};
