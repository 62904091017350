import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {schemaToJson} from '../utils/schema';
import {SCOPE_HOST} from '../reducers/sheet/constants';

export const contentApi = createApi({
  reducerPath: 'contentApi',
  baseQuery: fetchBaseQuery({baseUrl: `${window.location.origin}/services/content`}),
  endpoints: (builder) => ({
    getAllThemes: builder.query({
      query: () => `/signup-themes`,
    }),
    getTemplates: builder.query({
      query: () => ({
        url: `/signup-sheet-templates`,
        params: {make_protocol_relative: false},
      }),
      transformResponse: (data, meta, arg) => {
        const {id: templateId, type, schema} = arg;
        const template = data.find((d) => d.template_id === templateId && d.sheet_type === type);
        template.signup_options = (template.signup_options ?? []).map((so) =>
          schemaToJson(schema, type, SCOPE_HOST, so, true)
        );
        return template;
      },
    }),
  }),
});

export const {useGetAllThemesQuery, useLazyGetTemplatesQuery} = contentApi;
