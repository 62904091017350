import SparkMD5 from 'spark-md5';

/**
 * Generates an MD5 hash of the given value.
 *
 * @param {string} value
 * @return {string}
 */
export const generateMd5Hash = (value) => SparkMD5.hash(value);

/**
 * Generates a SHA-256 hash of the given value.
 *
 * @param {string} value
 * @return {Promise<string>}
 */
export const generateSha256Hash = async (value) => {
  const msgUint8 = new TextEncoder().encode(value);
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
};
