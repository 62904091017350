import cx from 'classnames';
import {PB_LinkIcon as LinkIcon} from '~/common/svg/PB_LinkIcon';
import {toTitleCase} from '~/SignUpSheets/utils/misc';
import {Link} from '~/common/_pb_components/atoms/Link';

import {tanzanite} from '~sass/pb_styleguide/base/_exports.sass';
import './UnknownUrl.sass';

export const UnknownUrl = ({url, displayText, className}) => (
  <Link
    href={url}
    variant="small"
    target="_blank"
    className={cx('unknown-url', className)}
    data-qa-id="affiliate-link"
  >
    <LinkIcon ratio={0.75} color={tanzanite} />
    {toTitleCase(displayText)}
  </Link>
);
