import {useState, useEffect} from 'react';
// https://usehooks.com/useOnScreen/

export function useImpression(ref, id) {
  const [isIntersecting, setIntersecting] = useState(false);
  const [hasFired, setHasFired] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting), {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    });

    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.disconnect();
    };
  }, []);

  if (!hasFired && isIntersecting) {
    window.dataLayer.push({event: id});
    setHasFired(true);
  }

  return isIntersecting;
}
