export const eviteVendors = [
  {
    name: 'Amazon',
    icon_url:
      'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/Amazon@2x.png',
    sq_icon_url:
      'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/81cfece92d5f4fe793d98fa25b99de9d.png?r=0.06251988008735476',
    email_reg_button_url:
      'https://g0.evitecdn.com/emails/project_beauty/registry_button-registry-amazon_2x.png',
    domains: ['www.amazon.com', 'amazon.com', 'amzn.com'],
  },
  {
    name: 'Target',
    icon_url:
      'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/Target@2x.png',
    sq_icon_url:
      'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/17ce297f4075481bb63803e89a150acc.png?r=0.5258412927975611',
    email_reg_button_url:
      'https://g0.evitecdn.com/emails/project_beauty/registry_button-registry-target_2x.png',
    domains: ['www.target.com', 'target.com', 'tgt.gifts'],
  },
  {
    name: 'Walmart',
    icon_url:
      'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/Walmart@2x.png',
    sq_icon_url:
      'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/074e295b2d5e4ef1b2aafde5eb533943.png?r=0.2282967197435306',
    email_reg_button_url:
      'https://g0.evitecdn.com/emails/project_beauty/registry_button-registry-walmart_2x.png',
    domains: ['walmart.com'],
  },
  {
    name: 'Bed Bath & Beyond',
    icon_url:
      'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/Bed%20Bath%20&%20Beyond@2x.png',
    sq_icon_url:
      'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/5d0f30bc04b44c629965a14dc8bea344.png?r=0.8762399265160528',
    email_reg_button_url:
      'https://g0.evitecdn.com/emails/project_beauty/registry_button-registry-bbb_2x.png',
    domains: ['bedbathandbeyond.com', 'www.bedbathandbeyond.com'],
  },
  {
    name: 'The Knot',
    icon_url:
      'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/The%20Knot@2x.png',
    sq_icon_url:
      'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/79ffb1b5008347f888798c30326fd62a.png?r=0.35173140771958034',
    email_reg_button_url:
      'https://g0.evitecdn.com/emails/project_beauty/registry_button-registry-theknot_2x.png',
    domains: ['theknot.com', 'www.theknot.com'],
  },
  {
    name: 'The Bump',
    icon_url:
      'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/The%20Bump@2x.png',
    domains: ['thebump.com', 'www.thebump.com'],
  },
  {
    name: 'Crate & Barrel',
    icon_url:
      'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/Crate%20&%20Barrel@2x.png',
    domains: ['crateandbarrel.com', 'www.crateandbarrel.com'],
  },
  {
    name: 'Zola',
    icon_url:
      'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/Zola@2x.png',
    sq_icon_url:
      'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/a19aecfba95f46bfa03e08957d57d909.png?r=0.35014288841736185',
    email_reg_button_url:
      'https://g0.evitecdn.com/emails/project_beauty/registry_button-registry-zola_2x.png',
    domains: ['zola.com', 'www.zola.com'],
  },
  {
    name: 'Babylist',
    icon_url:
      'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/Baby%20List@2x.png',
    sq_icon_url:
      'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/ed21fdd7c87d430985944ad4a88f9bc4.png?r=0.454778806334023',
    email_reg_button_url:
      'https://g0.evitecdn.com/emails/project_beauty/registry_button-registry-babylist_2x.png',
    domains: ['babylist.com', 'www.babylist.com'],
  },
  {
    name: 'Buy Buy Baby',
    icon_url:
      'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/Buy%20Buy%20Baby@2x.png',
    sq_icon_url:
      'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/a1cd3708dbcf42139ec044be877035b7.png?r=0.49909382870195573',
    email_reg_button_url:
      'https://g0.evitecdn.com/emails/project_beauty/registry_button-registry-buybaby_2x.png',
    domains: ['buybuybaby.com', 'www.buybuybaby.com'],
  },
  {
    name: 'Pottery Barn',
    icon_url:
      'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/Pottery%20Barn@2x.png',
    domains: ['potterybarn.com', 'www.potterybarn.com'],
  },
  {
    name: 'Pottery Barn Kids',
    icon_url:
      'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/PB%20Kids@2x.png',
    domains: ['potterybarnkids.com', 'www.potterybarnkids.com'],
  },
  {
    name: "Bloomingdale's",
    icon_url:
      'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/Bloomingdales@2x.png',
    domains: ['bloomingdales.com', 'www.bloomingdales.com'],
  },
  {
    name: "Macy's",
    icon_url:
      'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/macys@2x.png',
    domains: ['macys.com', 'www.macys.com'],
  },
  {
    name: "Kohl's",
    icon_url:
      'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/Khols@2x.png',
    domains: ['kohls.com', 'www.kohls.com'],
  },
  {
    name: 'Williams Sonoma',
    icon_url:
      'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/Williams%20Sonoma@2x.png',
    domains: ['williams-sonoma.com', 'www.williams-sonoma.com'],
  },
  {
    name: 'Restoration Hardware',
    icon_url:
      'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/RH@2x.png',
    domains: ['restorationhardware.com', 'www.restorationhardware.com'],
  },
  {
    name: 'Best Buy',
    icon_url:
      'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/Best%20Buy@2x.png',
    domains: ['bestbuy.com', 'www.bestbuy.com'],
  },
  {
    name: 'Ikea',
    icon_url:
      'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/IKEA@2x.png',
    domains: ['ikea.com', 'www.ikea.com', 'info.ikea-usa.com', 'ikea-usa.com'],
  },
  {
    name: 'myregistry.com',
    icon_url:
      'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/MyRegistry.com@2x.png',
    sq_icon_url:
      'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/54915ed248f346859c2b9d7f87178f30.png?r=0.9025306431922129',
    email_reg_button_url:
      'https://g0.evitecdn.com/emails/project_beauty/registry_button-registry-general_2x.png',
    domains: ['myregistry.com', 'www.myregistry.com'],
  },
  {
    name: "Blueprint | David's Bridal",
    icon_url:
      'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/Blueprint@2x.png',
    domains: ['blueprintregistry.com', 'www.blueprintregistry.com'],
  },
];
