import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const signUpSheetsApi = createApi({
  reducerPath: 'signUpSheetsApi',
  baseQuery: fetchBaseQuery({baseUrl: `${window.location.origin}/signup-sheet`}),
  endpoints: (builder) => ({
    getAllThemes: builder.query({
      query: () => `/themes`,
    }),
  }),
});

export const {useGetAllThemesQuery} = signUpSheetsApi;
