import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {selectEditorDisabled} from '~/CreateFabricGreetingCard/reducers/fabricDesign/selectors';

export const ToggleButton = ({children, onChange, className = '', active, dataQaId}) => {
  const editorDisabled = useSelector(selectEditorDisabled);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(active);
  }, [active]);

  const toggleChecked = useCallback(() => {
    const value = !checked;
    setChecked(value);
    onChange && onChange(value);
  }, [onChange, checked]);

  return (
    <div
      className={className}
      onClick={editorDisabled ? undefined : toggleChecked}
      role="button"
      style={{cursor: editorDisabled ? 'not-allowed' : 'pointer'}}
      data-qa-id={dataQaId}
    >
      {children}
    </div>
  );
};
