import {shallowEqual, useSelector} from 'react-redux';
import cx from 'classnames';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {useIsLargishAndUp} from '~/common/hooks/useMatchQuery';
import {Link} from '~/common/_pb_components/atoms/Link';
import {redirectToGoogleMaps} from '~/SignUpSheets/utils/misc';
import {
  selectAddress,
  selectIsPreviewMode,
  selectLocation,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {selectBlueprintId} from '~/SignUpSheets/reducers/blueprint/selectors';
import {usePreviewValue} from './hooks/usePreviewValue';
import {useFormattedAddress} from './hooks/useFormattedAddress';

export const DetailsLocation = ({className}) => {
  const isPreviewMode = useSelector(selectIsPreviewMode);
  const location = usePreviewValue(selectLocation, 'Location');
  const address = useSelector(selectAddress, shallowEqual);
  const blueprintId = useSelector(selectBlueprintId);
  const isLargishAndUp = useIsLargishAndUp();
  const {street, city, state, zip} = address;
  const hasAddress = street || city || state || zip;
  const showLocation = blueprintId !== 'snack' && (location || hasAddress || isPreviewMode);
  const cityStateZip = useFormattedAddress({addStreet: false});

  return (
    showLocation && (
      <div className={cx('signup-sheet__view-details__where', className)}>
        <Typography
          variant={isLargishAndUp ? 'header5' : 'paragraph3'}
          className="signup-sheet__view-details__where-address"
          color="charcoal"
          semibold={!isLargishAndUp}
        >
          {location}
        </Typography>

        {!hasAddress && isPreviewMode && (
          <Link
            className="signup-sheet__view-details__action-btn"
            data-qa-id="view-maps-link"
            target="_blank"
            href={redirectToGoogleMaps(address)}
            disabled
            variant={isLargishAndUp ? 'large' : 'small'}
          >
            View maps
          </Link>
        )}

        {hasAddress && (
          <>
            {address.street && (
              <Typography
                variant={isLargishAndUp ? 'paragraph1' : 'paragraph3'}
                className="signup-sheet__view-details__where-address"
                color="pebble"
              >
                {address.street}
              </Typography>
            )}

            {cityStateZip && (
              <Typography
                variant={isLargishAndUp ? 'paragraph1' : 'paragraph3'}
                className="signup-sheet__view-details__where-address"
                color="pebble"
              >
                {cityStateZip}
              </Typography>
            )}

            <Link
              data-qa-id="view-maps-link"
              target="_blank"
              href={redirectToGoogleMaps(address)}
              disabled={isPreviewMode}
              variant={isLargishAndUp ? 'large' : 'small'}
            >
              View maps
            </Link>
          </>
        )}
      </div>
    )
  );
};
