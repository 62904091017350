import {useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import urls from 'urls';
import {Button} from '~/common/_pb_components/atoms/Button';
import {PB_Arrow as ArrowIcon} from '~/common/svg/PB_Arrow';
import {PB_CloseLine as CloseIcon} from '~/common/svg/PB_CloseLine';
import {
  CreateFlowLeaveModal,
  EditFlowLeaveModal,
} from '~/SignUpSheets/components/LeaveModal/LeaveModal';
import {useSheetActions} from '~/SignUpSheets/hooks/useSheetActions';
import {
  selectIsInEditMode,
  selectIsPristine,
  selectSheetId,
  selectSheetStatus,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export const HeaderLeaveButton = () => {
  const {autoSave, isSavingSheet} = useSheetActions();
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const history = useHistory();
  const isInEditMode = useSelector(selectIsInEditMode);
  const sheetId = useSelector(selectSheetId);
  const sheetStatus = useSelector(selectSheetStatus);
  const isPristine = useSelector(selectIsPristine);

  const viewMode = window.location.pathname.startsWith('/signup-sheet/');

  const LeaveIcon = viewMode ? CloseIcon : ArrowIcon;

  const goToMyEvents = useCallback(() => {
    const params = new URLSearchParams();
    params.set('type', 'signup-sheets');
    window.location.href = `${urls.get('my_events')}?${params.toString()}`;
  }, []);

  const handleClose = useCallback(() => {
    setShowLeaveModal(false);
    if (viewMode) {
      goToMyEvents();
    } else if (sheetId && sheetStatus !== 'draft') {
      window.location.href = `/signup-sheet/${sheetId}`;
    } else if (history.length > 1) {
      history.goBack();
    } else {
      window.location.href = `/signup-sheets`;
    }
  }, [goToMyEvents, sheetId, sheetStatus, viewMode]);

  const handleCloseLeaveModal = useCallback(
    () => (isPristine ? handleClose() : setShowLeaveModal(true)),
    [isPristine, handleClose, setShowLeaveModal]
  );

  const handleSaveAndLeave = useCallback(async () => {
    await autoSave();
    setShowLeaveModal(false);
    handleClose();
  }, [autoSave]);

  return (
    <>
      <Button
        variant="transparent"
        data-qa-id="close-premium-preview"
        className="close-premium-preview-desktop"
        onClick={handleCloseLeaveModal}
      >
        <LeaveIcon ratio={0.75} color={charcoal} direction="left" />
      </Button>
      {isInEditMode && showLeaveModal && (
        <EditFlowLeaveModal cancelLeave={() => setShowLeaveModal(false)} leave={goToMyEvents} />
      )}
      {!isInEditMode && showLeaveModal && (
        <CreateFlowLeaveModal
          cancelLeave={() => setShowLeaveModal(false)}
          leave={handleClose}
          saveAndLeave={handleSaveAndLeave}
          saving={isSavingSheet}
        />
      )}
    </>
  );
};
