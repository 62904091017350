import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import cx from 'classnames';
import {Button} from '~/common/_pb_components/atoms/Button';
import {PB_EditLine as PBEditLine} from '~/common/svg/PB_EditLine';
import {PB_Eye as PBEye} from '~/common/svg/PB_Eye';
import {UPDATE_SHEET_DETAILS} from '../../../../reducers/sheet/constants';
import {SignUpSheetContext} from '../../../../context/SignUpSheetContext';
import {selectIsPreviewMode} from '../../../../reducers/sheet/selectors';
import {useBrowserWidth} from '~/common/hooks';
import {Toggle} from '~/common/_pb_components/atoms/Toggle';
import {useMatchQuery} from '~/common/hooks/useMatchQuery';

import {pebble, largishAndUpQuery} from '~sass/pb_styleguide/base/_exports.sass';
import './PreviewToggle.sass';

export const PreviewToggle = () => {
  const dispatch = useDispatch();
  const isPreviewMode = useSelector(selectIsPreviewMode);
  const [backgroundStyle, setBackgroundStyle] = useState({});
  const editButtonRef = useRef(null);
  const previewButtonRef = useRef(null);
  const browserWidth = useBrowserWidth();
  const {flow} = useContext(SignUpSheetContext);
  const {matches: isDesktop} = useMatchQuery(`(${largishAndUpQuery})`);

  const togglePreviewMode = useCallback(
    (value) => () =>
      dispatch({
        type: UPDATE_SHEET_DETAILS,
        payload: {previewMode: value},
      }),
    [dispatch]
  );

  const updateBackgroundPlacement = useCallback(() => {
    const activeButtonRef = isPreviewMode ? previewButtonRef : editButtonRef;
    if (activeButtonRef.current) {
      const {offsetWidth, offsetLeft} = activeButtonRef.current;
      setBackgroundStyle({
        width: offsetWidth,
        transform: `translateX(${offsetLeft}px)`,
      });
    }
  }, [isPreviewMode]);

  useEffect(() => {
    if (isDesktop) return;
    updateBackgroundPlacement();
  }, [updateBackgroundPlacement, browserWidth, isDesktop]);

  if (!['create', 'edit'].includes(flow)) {
    return null;
  }

  return (
    <div className="preview-toggle__wrapper">
      {isDesktop ? (
        <Toggle
          options={[
            {
              label: 'Edit',
              value: false,
              dataQaId: 'preview-toggle-edit-button',
              icon: <PBEditLine ratio={0.5} color={pebble} />,
              className: cx('preview-toggle__button', {
                'preview-toggle__button--active': !isPreviewMode,
              }),
              ref: editButtonRef,
            },
            {
              label: 'Preview',
              value: true,
              dataQaId: 'preview-toggle-preview-button',
              icon: <PBEye ratio={0.5} color={pebble} />,
              className: cx('preview-toggle__button', {
                'preview-toggle__button--active': isPreviewMode,
              }),
              ref: previewButtonRef,
            },
          ]}
          value={isPreviewMode}
          className="preview-toggle__buttons"
          onChange={(val) => togglePreviewMode(val)()}
        />
      ) : (
        <div className="preview-toggle__buttons">
          <div className="preview-toggle__background" style={backgroundStyle} />
          <Button
            ref={editButtonRef}
            variant="unstyled-button"
            className={cx('preview-toggle__button', {
              'preview-toggle__button--active': !isPreviewMode,
            })}
            icon={<PBEditLine ratio={0.5} color={pebble} />}
            onClick={togglePreviewMode(false)}
            data-qa-id="preview-toggle-edit-button"
          >
            Edit
          </Button>
          <Button
            ref={previewButtonRef}
            variant="unstyled-button"
            className={cx('preview-toggle__button', {
              'preview-toggle__button--active': isPreviewMode,
            })}
            icon={<PBEye ratio={0.5} color={pebble} />}
            onClick={togglePreviewMode(true)}
            data-qa-id="preview-toggle-preview-button"
          >
            Preview
          </Button>
        </div>
      )}
    </div>
  );
};
