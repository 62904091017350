import {useEffect, useRef, useState} from 'react';
import cx from 'classnames';
import {Button} from '~/common/_pb_components/atoms/Button';
import {PB_Chevron as PBChevron} from '~/common/svg/PB_Chevron';
import {useIsLargishAndUp} from '~/common/hooks/useMatchQuery';
import {sanitizeString} from '~/common/utils/sanitize_html';
import {linkColor} from '~sass/pb_styleguide/base/_exports.sass';

export const DetailsOrganizerNote = ({organizerNote}) => {
  const [noteExpanded, setNoteExpanded] = useState(false);
  const [noteIsExpandable, setNoteIsExpandable] = useState(false);
  const noteRef = useRef(null);
  const isLargishAndUp = useIsLargishAndUp();

  useEffect(() => {
    const noteEl = noteRef.current?.firstElementChild;

    if (!noteEl) return;
    setNoteIsExpandable(noteEl.clientHeight > (isLargishAndUp ? 80 : 68));
  }, [isLargishAndUp, organizerNote]);

  return (
    <div>
      <div
        className={cx('signup-sheet__view-details__organizer-note', {
          'signup-sheet__view-details__organizer-note--expanded': noteExpanded,
          'signup-sheet__view-details__organizer-note--hidden': !organizerNote,
        })}
        ref={noteRef}
      >
        <div
          className={cx('signup-sheet__view-details__organizer-note-text', {
            truncated: noteIsExpandable && !noteExpanded,
            paragraph1: isLargishAndUp,
            paragraph2: !isLargishAndUp,
          })}
        >
          {sanitizeString(organizerNote)}
        </div>
      </div>

      {noteIsExpandable && (
        <Button
          className="signup-sheet__view-details__show-more"
          variant="link"
          onClick={() => setNoteExpanded(!noteExpanded)}
          data-qa-id="organizer-note-show-more"
          size={isLargishAndUp ? 'large' : 'small'}
        >
          Show {noteExpanded ? 'less' : 'more'}
          <PBChevron color={linkColor} ratio={0.5} direction={noteExpanded ? 'up' : 'down'} />
        </Button>
      )}
    </div>
  );
};
