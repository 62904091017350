import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Typography} from '~/common/_pb_components/atoms/Typography';

const VendorDiscContent = ({imgSrc, vendorName, displayText, textColor, semibold, className}) => (
  <div
    className={cx('vendor-disc__content', {
      [className]: !!className,
    })}
  >
    <img className="vendor-disc__img" src={imgSrc} alt={vendorName} />
    <Typography
      className={cx('vendor-disc__text', {
        'vendor-disc__text--semibold': semibold,
      })}
      variant="paragraph3"
      classNames="small"
      color={textColor}
    >
      {displayText}
    </Typography>
  </div>
);

export const VendorDisc = ({
  disableClick = false,
  imgSrc,
  displayText,
  vendorName,
  className,
  dataQaId = 'vendor-disc-link',
  href,
  textColor = 'coin',
  semibold = false,
  size = 'medium',
}) => (
  <a
    href={href}
    target="_blank"
    data-qa-id={dataQaId}
    data-impression-tracking="true"
    data-qa-locator="vendor-disc"
    data-qa-value={vendorName}
    rel="noreferrer"
    className={`vendor-disc__wrapper vendor-disc__wrapper--${size}`}
    onClick={(e) => {
      if (disableClick) {
        e.preventDefault();
        return false;
      }
      return true;
    }}
  >
    <VendorDiscContent
      imgSrc={imgSrc}
      vendorName={vendorName}
      displayText={displayText}
      textColor={textColor}
      semibold={semibold}
      className={className}
    />
  </a>
);

VendorDisc.propTypes = {
  disableClick: PropTypes.bool,
  imgSrc: PropTypes.string.isRequired,
  displayText: PropTypes.string.isRequired,
  vendorName: PropTypes.string.isRequired,
  className: PropTypes.string,
  dataQaId: PropTypes.string,
  href: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  semibold: PropTypes.bool,
  size: PropTypes.string,
};
