import cx from 'classnames';
import {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {LinkPreview} from './LinkPreview';
import {toTitleCase} from '~/common/fabric/CreateDesign/components/CardEditor/actions/TextFormat/components/Casing/Casing';
import {PopOver} from '~/common/_pb_components/molecules/PopOver';
import {Divider} from '~/common/_pb_components/atoms/Divider';
import {useIsLargishAndUp} from '~/common/hooks/useMatchQuery';
import {AffiliateIcons, isValidAmazonAffiliateUrl} from '~/SignUpSheets/utils/affiliate';
import {selectIsOrganizer} from '~/SignUpSheets/reducers/sheet/selectors';

import {cottonCandy, charcoal} from '~sass/pb_styleguide/base/_exports.sass';
import './VolunteerLinkPreview.sass';

export const VolunteerLinkPreview = ({
  uuid,
  productLink,
  organizerName,
  compact = false,
  isAffiliateUrl = !!productLink?.vendor_name,
  disabled = isAffiliateUrl,
  showDisclaimerPopover = disabled,
  endAdornment,
}) => {
  const isLargishAndUp = useIsLargishAndUp();
  const isOrganizer = useSelector(selectIsOrganizer);

  const DisclaimerButton = useMemo(
    () =>
      isLargishAndUp && disabled && showDisclaimerPopover ? (
        <PopOver
          id={`product-link-info-disclaimer-${uuid}`}
          buttonId={`product-link-info-disclaimer-${uuid}__trigger`}
          position="bottom"
          bkgColor={cottonCandy}
          textColor={charcoal}
          className="product-link-info-disclaimer-trigger"
          contentClassName="product-link-info-disclaimer-popover-content"
          closeOnOutsideClick
          data-qa-id="product-link-info-disclaimer"
        >
          We&apos;ll share the link once you sign up.
        </PopOver>
      ) : null,
    [isLargishAndUp, disabled, showDisclaimerPopover, uuid]
  );

  const DisclaimerWall = useMemo(
    () => (
      <PopOver
        id={`disabled-link-preview-${uuid}`}
        buttonId={`disabled-link-preview-${uuid}__trigger`}
        buttonClassName="link-preview__disabled-link-preview"
        buttonContent={<div className="link-preview__disabled-link-wall" />}
        position="top"
        bkgColor={cottonCandy}
        textColor={charcoal}
        className="link-preview__disclaimer-popover-trigger"
        contentClassName="link-preview__disclaimer-popover-content"
        arrowClassName="link-preview__disclaimer-popover-arrow"
        closeOnOutsideClick
        data-qa-id="disabled-product-link-disclaimer"
      >
        We&apos;ll share the link once you sign up.
      </PopOver>
    ),
    [uuid]
  );

  const PreviewContent = useMemo(
    () => (
      <LinkPreview
        disabled={disabled && !!productLink.vendor_name}
        href={productLink.affiliate_url || productLink.url}
        displayText={productLink.display_text}
        image={
          productLink.images?.primary?.medium?.url ?? AffiliateIcons.get(productLink.vendor_name)
        }
        identifiedProduct={!!productLink.title}
        title={
          productLink.title ??
          `${organizerName} has a suggested purchase from ${toTitleCase(productLink?.vendor_name ?? '')}`
        }
        titleVariant={isValidAmazonAffiliateUrl(productLink) ? 'paragraph2' : 'paragraph3'}
        titleColor={productLink.vendor_name ? 'pebble' : 'charcoal'}
        imgClassName={cx({'link-preview__image__mini-sq': !productLink.title && isAffiliateUrl})}
        actionButtonClassName={cx({
          'link-preview__action__inline': !productLink.title && isAffiliateUrl,
        })}
        subtitle={productLink.price?.display_amount}
        subtitleVariant="list2"
        vendor={productLink.vendor_name}
        compact={compact}
        DisclaimerPopover={
          showDisclaimerPopover && productLink.vendor_name === 'amazon' ? DisclaimerButton : null
        }
        endAdornment={disabled ? null : endAdornment}
        stackEndAdornment={!isLargishAndUp}
      />
    ),
    [
      disabled,
      showDisclaimerPopover,
      isAffiliateUrl,
      productLink,
      organizerName,
      isLargishAndUp,
      compact,
      DisclaimerButton,
    ]
  );

  if (!productLink?.url || (!isOrganizer && productLink.not_safe)) return null;
  return productLink ? (
    <>
      {!isLargishAndUp && <Divider />}
      <div
        className={cx('link-preview__volunteer-link-preview', {
          affiliate: productLink.vendor_name === 'amazon',
        })}
      >
        <div className={cx('volunteer-link-preview__content-wrapper', {disabled})}>
          {PreviewContent}
          {showDisclaimerPopover && productLink.vendor_name ? DisclaimerWall : null}
        </div>
        {showDisclaimerPopover && productLink.vendor_name && productLink.vendor_name !== 'amazon'
          ? DisclaimerButton
          : null}
      </div>
    </>
  ) : null;
};

VolunteerLinkPreview.propTypes = {
  uuid: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  productLink: PropTypes.object,
  organizerName: PropTypes.string.isRequired,
  compact: PropTypes.bool,
  isAffiliateUrl: PropTypes.bool,
  disabled: PropTypes.bool,
  showDisclaimerPopover: PropTypes.bool,
  endAdornment: PropTypes.node,
};
