import {INIT_APP} from '~/common/fabric/constants';
import {SET_BLUEPRINT} from './constants';

export const initialState = {
  blueprint_id: null,
  blueprint_type: null,
  show_when: false,
  show_where: true,
  background_urls: [],
  field_schema: [],
  max_rows: 100,
  theme_id: '',
  ready: false,
};

export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case INIT_APP: {
      const {blueprint = {}} = action.payload;
      return {
        ...state,
        ...blueprint,
        ready: Object.keys(blueprint) > 0,
      };
    }
    case SET_BLUEPRINT: {
      return {
        ...state,
        ...action.payload,
        ready: true,
      };
    }
    default: {
      return state;
    }
  }
};
