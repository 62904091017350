import {configureStore} from '@reduxjs/toolkit';
import {promiseResolverMiddleware} from '~/CreateFabricInvitation/middleware/PromiseResolver';
import {reducer as blueprint} from '~/SignUpSheets/reducers/blueprint/reducer';
import {reducer as sheet} from '~/SignUpSheets/reducers/sheet/reducer';
import {reducer as themes} from '~/SignUpSheets/reducers/themes/reducer';
import {reducer as user} from '~/common/fabric/User/reducers/user';
import {reducer as loginModal} from '~/common/components/LoginModal/reducer';
import {api} from '~/SignUpSheets/api/api';
import {contentApi} from '~/SignUpSheets/api/content';
import {signUpSheetsApi} from '~/SignUpSheets/api/signUpSheets';
import {affiliateApi} from '~/SignUpSheets/api/affiliate';

const reducers = {
  blueprint,
  sheet,
  themes,
  user,
  [api.reducerPath]: api.reducer,
  [contentApi.reducerPath]: contentApi.reducer,
  [signUpSheetsApi.reducerPath]: signUpSheetsApi.reducer,
  [affiliateApi.reducerPath]: affiliateApi.reducer,
  loginModal,
};

export const middlewares = (getDefaultMiddleware) =>
  getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  }).concat(
    api.middleware,
    contentApi.middleware,
    signUpSheetsApi.middleware,
    affiliateApi.middleware,
    promiseResolverMiddleware
  );

export const store = configureStore({
  reducer: reducers,
  middleware: middlewares,
});
