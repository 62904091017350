import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {selectEditorDisabled} from '~/CreateFabricGreetingCard/reducers/fabricDesign/selectors';
import {ToggleButton} from '~/common/fabric/CreateDesign/components/CardEditor/components/ToggleButton/ToggleButton';

import './TextFormatCasingBase.sass';

export const TextFormatCasingBase = ({active, onClick, dataQaId, label, value}) => {
  const editorDisabled = useSelector(selectEditorDisabled);
  const onClickHandler = useCallback(
    (checked) => {
      onClick(checked ? value : null);
    },
    [onClick, value]
  );

  return (
    <ToggleButton onChange={onClickHandler} active={active}>
      <button
        data-qa-id={dataQaId}
        className={`unstyled-button text_format_casing_base ${
          active ? 'text_format_casing_base--checked' : ''
        }`}
        disabled={editorDisabled}
      >
        {label}
      </button>
    </ToggleButton>
  );
};
