import React from 'react';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export const Apple = (props) => {
  const {className = '', ratio = 1} = props;

  // default dimensions
  const width = 32;
  const height = 32;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4987 9.62942C14.3307 9.62942 12.5227 8.30142 10.6187 8.34942C8.10666 8.38142 5.80266 9.80542 4.50666 12.0614C1.89866 16.5894 3.83466 23.2774 6.37866 26.9574C7.62666 28.7494 9.09866 30.7654 11.0507 30.7014C12.9227 30.6214 13.6267 29.4854 15.8987 29.4854C18.1547 29.4854 18.7947 30.7014 20.7787 30.6534C22.7947 30.6214 24.0747 28.8294 25.3067 27.0214C26.7307 24.9414 27.3227 22.9254 27.3547 22.8134C27.3067 22.7974 23.4347 21.3094 23.3867 16.8294C23.3547 13.0854 26.4427 11.2934 26.5867 11.2134C24.8267 8.63742 22.1227 8.34942 21.1787 8.28542C18.7147 8.09342 16.6507 9.62942 15.4987 9.62942ZM19.6587 5.85342C20.6987 4.60542 21.3867 2.86142 21.1947 1.13342C19.7067 1.19742 17.9147 2.12542 16.8427 3.37342C15.8827 4.47742 15.0507 6.25342 15.2747 7.94942C16.9227 8.07742 18.6187 7.10142 19.6587 5.85342Z"
        fill={charcoal}
      />
    </svg>
  );
};
