import {ResponsiveModal} from '~/common/_pb_components/organisms/ResponsiveModal';

export const ConfirmNotifyModal = ({onPrimaryAction, onSecondaryAction, onClose}) => (
  <ResponsiveModal
    isOpen
    title="Do you want to notify volunteers about the changes made?"
    primaryText="Yes, notify of changes"
    primaryAction={onPrimaryAction}
    secondaryText="Don't notify"
    secondaryAction={onSecondaryAction}
    onClose={onClose}
    equalButtons
    body="We recommend notifying volunteers to make them aware of the changes."
  />
);
