import React, {createContext, useMemo, useState} from 'react';
import {useVideoControl} from '~/common/hooks/useVideoControls';

const defaultContextValue = {
  setCanvas: () => null,
  canvas: null,
  activeObjects: [],
  effectsVideo: null,
};

export const FabricContext = createContext(defaultContextValue);

export const FabricContextParent = ({children}) => {
  const [canvas, setCanvas] = useState();
  const [activeObjects, setActiveObjects] = useState();
  const effectsVideo = useVideoControl();

  const context = useMemo(
    () => ({
      canvas,
      setCanvas,
      activeObjects,
      setActiveObjects,
      effectsVideo,
    }),
    [canvas, setCanvas, activeObjects, setActiveObjects, effectsVideo]
  );

  return <FabricContext.Provider value={context}>{children}</FabricContext.Provider>;
};
