import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const affiliateApi = createApi({
  reducerPath: 'affiliateApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `https://${window.affiliateAPIBaseUrl}`,
  }),
  endpoints: (builder) => ({
    processAffiliateUrls: builder.mutation({
      query: (body) => ({
        url: `/api/affiliate/v1/process`,
        body,
        method: 'POST',
      }),
    }),
  }),
});

export const {useProcessAffiliateUrlsMutation} = affiliateApi;
