import {api} from './api';

export const sheetApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBlueprint: builder.query({
      query: (id) => ({url: `${window.location.origin}/signup-sheet/blueprint/${id}`}),
      providesTags: (result, error, id) => [{type: 'Blueprint', id}],
    }),
    getSheet: builder.query({
      query: (id) => ({url: `/signup/${id}/`}),
    }),
    saveSheetUpdates: builder.mutation({
      query(body) {
        return {
          url: body.id ? `/signup/${body.id}/` : `/signup/`,
          method: body.id ? 'PUT' : 'POST',
          body: JSON.stringify(body),
        };
      },
    }),
    publishSheet: builder.mutation({
      query({id, organizer_id, blueprint_id}) {
        return {
          url: `/signup/${id}/publish/`,
          method: 'POST',
          body: JSON.stringify({utm: window.getUTM(), id, organizer_id, blueprint_id}),
        };
      },
    }),
    cancelSheet: builder.mutation({
      query(id) {
        return {
          url: `/signup/${id}/cancel/`,
          method: 'POST',
        };
      },
    }),
    saveSignups: builder.mutation({
      query: ({id, signups}) => ({
        url: `/signup/${id}/signups/`,
        method: 'POST',
        body: JSON.stringify(signups),
      }),
    }),
    removeSignup: builder.mutation({
      query: ({id, row_id: rowId}) => ({
        url: `/signup/${id}/row/${rowId}/`,
        method: 'DELETE',
      }),
    }),
    updateSignups: builder.mutation({
      query: ({id, signups}) => ({
        url: `/signup/${id}/rows/`,
        method: 'PUT',
        body: JSON.stringify(signups),
      }),
    }),
  }),
});

export const {
  useLazyGetBlueprintQuery,
  useLazyGetSheetQuery,
  useSaveSheetUpdatesMutation,
  usePublishSheetMutation,
  useCancelSheetMutation,
  useSaveSignupsMutation,
  useRemoveSignupMutation,
  useUpdateSignupsMutation,
} = sheetApi;
