import React from 'react';
import {fog} from '~sass/pb_styleguide/base/_exports.sass';

export const PB_SocialYt = (props) => {
  const {color = fog, ratio = 1, className} = props;

  // default dimensions
  const width = 32;
  const height = 32;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${width} ${height}`}
      style={style}
      className={className}
    >
      <path
        d="M28.724 8.66404C29.3333 11.04 29.3333 16 29.3333 16C29.3333 16 29.3333 20.96 28.724 23.336C28.3853 24.6494 27.3947 25.6827 26.14 26.032C23.8613 26.6667 16 26.6667 16 26.6667C16 26.6667 8.14267 26.6667 5.86 26.032C4.6 25.6774 3.61067 24.6454 3.27601 23.336C2.66667 20.96 2.66667 16 2.66667 16C2.66667 16 2.66667 11.04 3.27601 8.66404C3.61467 7.35071 4.60534 6.31737 5.86 5.96804C8.14267 5.33337 16 5.33337 16 5.33337C16 5.33337 23.8613 5.33337 26.14 5.96804C27.4 6.32271 28.3893 7.35471 28.724 8.66404ZM13.3333 20.6667L21.3333 16L13.3333 11.3334V20.6667Z"
        fill={color}
      />
    </svg>
  );
};
