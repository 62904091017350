import {jwtDecode} from 'jwt-decode';
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {updateUser} from '~/common/auth/utils';
import {store} from '../reducers/store';

export const api = createApi({
  reducerPath: 'signupSheetAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: `https://${window.signUpSheetsAPIServer}`,
    credentials: 'include',
    headers: {
      'X-CSRFToken': evite.cookie('csrftoken'),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    prepareHeaders: async (headers) => {
      try {
        const userData = JSON.parse(localStorage.getItem('userData'));
        if (userData) {
          let {token} = userData.content;
          const currentTime = Date.now() / 1000;
          const tokenExpiration = jwtDecode(userData.content.token).exp;
          if (currentTime > tokenExpiration) {
            evite.error('User token has expired, please log in again');
            const responseData = await updateUser(store);
            token = responseData.token;
          }
          headers.set('Authorization', `Bearer ${token}`);
        }
      } catch (err) {
        evite.error('Unable to parse local user data for token to authenticate request');
      }
      return headers;
    },
    responseHandler: async (response) => {
      const data = await response.json();
      return response.ok ? {data} : {error: {status: response.status, data}};
    },
  }),
  tagTypes: ['Sheet', 'Blueprint', 'Assets'],
  endpoints: () => ({}),
});
