export const SignUpSheetsIcon = (props) => {
  const {className = '', ratio = 1, width = 240, height = 28.76} = props;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1596_1729)">
        <g clipPath="url(#clip1_1596_1729)">
          <path
            d="M29.4337 6.18259C29.6985 6.18351 29.9609 6.13226 30.2055 6.03186C30.4501 5.93146 30.6719 5.78392 30.858 5.59788C31.1435 5.31352 31.3383 4.9527 31.418 4.56015C31.4977 4.1676 31.4589 3.76059 31.3065 3.38958C31.2051 3.14318 31.0553 2.91901 30.8657 2.72998C30.6779 2.54316 30.4539 2.39557 30.2071 2.296C29.9619 2.19661 29.6987 2.14792 29.4337 2.15294C28.8918 2.15294 28.3722 2.36537 27.989 2.74349C27.6059 3.12161 27.3906 3.63446 27.3906 4.16921C27.3906 4.70395 27.6059 5.2168 27.989 5.59492C28.3722 5.97305 28.8918 6.18547 29.4337 6.18547V6.18259Z"
            fill="#101B21"
          />
          <path
            d="M54.7336 19.0578C53.8531 20.2579 52.7752 20.786 51.4024 20.786C48.2318 20.786 46.0097 17.8778 46.0097 13.8798V13.5169H54.933C54.933 10.7728 53.1341 7.76763 49.3759 7.76763C45.9776 7.76763 42.7088 11.0378 42.7088 15.4006C42.6959 16.6567 42.9966 17.8966 43.5844 19.0107C43.505 19.1812 43.415 19.3467 43.3149 19.5061C42.6339 20.4567 41.981 20.9137 41.0043 20.9137C39.5274 20.9137 38.8484 19.7567 38.8484 18.1716V9.15501H43.129L43.3129 8.09887H41.5909C40.5908 8.10944 39.4827 8.09887 38.8669 8.09887L38.8484 4.16235H38.1615C38.1615 5.69856 36.1477 8.09887 33.7174 8.09887H21.3803V8.79305C22.6869 8.79305 23.3086 8.85929 23.3086 9.55347C23.288 9.92873 23.197 10.297 23.0401 10.6394L20.1321 17.9709L16.9936 10.5357C16.8321 10.071 16.7348 9.74165 16.7348 9.51026C16.7348 8.84874 17.3555 8.78248 18.4675 8.78248V8.08927H11.1768V8.78248C12.5193 8.78248 12.7129 9.0542 13.3667 10.5011L18.4101 22.2972H19.527L24.118 10.6336C24.7893 8.96971 25.014 8.7844 26.02 8.7844C27.4433 8.7844 27.7653 8.96875 27.7653 10.5664V19.8191C27.7653 21.4389 27.4394 21.6031 25.9684 21.6031V22.2972H33.0588V21.6031C31.5887 21.6031 31.2619 21.4389 31.2619 19.8191V9.11277H35.4452L35.348 18.6612C35.348 21.0078 37.0807 22.6275 39.3008 22.6275C41.1677 22.6275 42.6582 21.6616 43.704 20.0477C43.7973 19.9068 43.8821 19.7606 43.9579 19.6098L44.2741 20.0477C44.9296 20.8611 45.7631 21.5176 46.7122 21.9679C47.653 22.4129 48.6848 22.6389 49.7281 22.6285C51.8188 22.6285 53.8794 21.5416 55.1538 19.4601L54.7336 19.0578ZM49.2776 8.65863V8.66343C51.0103 8.66343 51.8295 10.3485 51.9249 12.696H46.0759C46.3376 10.0489 47.5839 8.65863 49.2776 8.65863Z"
            fill="#101B21"
          />
          <path
            d="M8.69269 20.7911C5.52207 20.7911 3.30001 17.8828 3.30001 13.8839V13.5219H12.2213C12.2213 10.7779 10.4254 7.77173 6.66618 7.77173C3.26791 7.77173 0 11.0419 0 15.4047C0 19.6667 3.16867 22.6412 7.02517 22.6412C9.11686 22.6412 11.1755 21.5553 12.45 19.4728L12.0258 19.0743C11.1434 20.2582 10.0654 20.7911 8.69269 20.7911ZM6.56889 8.65985C8.29964 8.65985 9.11978 10.3449 9.21512 12.6924H3.36422C3.6269 10.0492 4.86829 8.65985 6.56889 8.65985Z"
            fill="#101B21"
          />
        </g>
        <path
          d="M234.992 21.4187C236.65 21.4187 237.699 20.6066 237.699 19.0163C237.699 17.832 236.887 17.2906 235.635 16.6477L233.131 15.3619C231.575 14.5499 230.289 13.4333 230.289 11.4708C230.289 8.96687 232.353 7.24121 234.925 7.24121C236.481 7.24121 237.53 8.01945 238.072 8.01945C238.41 8.01945 238.545 7.74876 238.714 7.37656H239.12L239.391 11.7414L238.816 11.7753C238.376 9.33907 236.684 8.08712 235.026 8.08712C233.504 8.08712 232.59 9.03454 232.59 10.3542C232.59 11.5723 233.47 12.1475 234.688 12.7904L236.955 13.9408C238.613 14.7867 240 15.8695 240 17.9673C240 20.4712 237.835 22.3322 235.06 22.3322C232.928 22.3322 231.812 21.1818 231.338 21.1818C231 21.1818 230.729 21.6555 230.628 22.0954H230.154L229.917 17.1553L230.56 17.1214C230.898 19.5238 232.658 21.4187 234.992 21.4187Z"
          fill="#51A1EB"
        />
        <path
          d="M219.201 8.18877C220.995 6.97066 222.652 5.34651 223.735 3.79004H224.006V7.64739C225.089 7.64739 227.762 7.61355 228.743 7.51204L228.54 8.62864H224.006V17.8998C224.006 19.5578 224.717 20.7759 226.307 20.7759C227.356 20.7759 228.066 20.3022 228.777 19.3209L229.251 19.6931C228.202 21.385 226.781 22.3324 224.852 22.3324C222.72 22.3324 221.028 20.7759 221.028 18.5089L221.13 8.62864H219.201V8.18877Z"
          fill="#51A1EB"
        />
        <path
          d="M212.321 8.12096C210.46 8.12096 209.039 9.57592 208.667 12.249H215.197C215.096 9.84661 214.216 8.12096 212.321 8.12096ZM218.209 19.3208C216.923 21.3171 214.926 22.3322 212.896 22.3322C209.005 22.3322 205.757 19.287 205.757 14.9221C205.757 10.4895 209.039 7.24121 212.355 7.24121C216.246 7.24121 217.972 10.2526 217.972 13.0611H208.565C208.565 13.2641 208.565 13.4671 208.565 13.6701C208.565 17.629 210.866 20.6404 214.284 20.6404C215.772 20.6404 216.923 20.099 217.836 18.9486L218.209 19.3208Z"
          fill="#51A1EB"
        />
        <path
          d="M198.509 8.12096C196.648 8.12096 195.226 9.57592 194.854 12.249H201.385C201.283 9.84661 200.403 8.12096 198.509 8.12096ZM204.396 19.3208C203.11 21.3171 201.114 22.3322 199.084 22.3322C195.193 22.3322 191.944 19.287 191.944 14.9221C191.944 10.4895 195.226 7.24121 198.542 7.24121C202.434 7.24121 204.159 10.2526 204.159 13.0611H194.753C194.753 13.2641 194.753 13.4671 194.753 13.6701C194.753 17.629 197.054 20.6404 200.471 20.6404C201.96 20.6404 203.11 20.099 204.024 18.9486L204.396 19.3208Z"
          fill="#51A1EB"
        />
        <path
          d="M182.025 21.2831V21.9936H175.055V21.2831C176.645 21.2831 177.052 21.1477 177.052 19.4898V2.50389C177.052 0.84591 176.645 0.710565 174.717 0.710565V0H180.029V10.3878C180.672 9.06815 182.398 7.24098 184.665 7.24098C187.71 7.24098 189.063 9.47419 189.063 12.1811V19.4898C189.063 21.1477 189.469 21.2831 191.094 21.2831V21.9936H184.123V21.2831C185.714 21.2831 186.086 21.1477 186.086 19.4898V12.7563C186.086 10.2863 185.037 8.96664 183.108 8.96664C181.721 8.96664 180.57 9.94789 180.029 11.0645V19.4898C180.029 21.1477 180.435 21.2831 182.025 21.2831Z"
          fill="#51A1EB"
        />
        <path
          d="M166.859 21.3507C169.159 21.3507 171.122 20.2003 171.122 17.561C171.122 15.6662 170.005 14.7526 167.874 13.6022L164.558 11.8427C162.257 10.5907 160.734 9.27112 160.734 6.69956C160.734 3.55277 163.407 1.15039 166.892 1.15039C169.227 1.15039 170.581 2.26699 171.257 2.26699C171.731 2.26699 171.9 1.9963 172.103 1.45492H172.543L172.814 7.44396L172.205 7.47779C171.832 4.36485 169.802 2.13165 166.96 2.13165C164.761 2.13165 163.272 3.65428 163.272 5.48145C163.272 7.5793 164.761 8.39138 166.554 9.33879L169.396 10.8614C171.832 12.1472 173.727 13.5683 173.727 16.2753C173.727 19.7942 170.716 22.332 166.926 22.332C164.287 22.332 162.934 20.9785 162.155 20.9785C161.715 20.9785 161.445 21.4522 161.276 22.0613H160.768L160.328 15.2602L161.039 15.2263C161.648 19.0498 164.084 21.3507 166.859 21.3507Z"
          fill="#51A1EB"
        />
        <path
          d="M145.398 21.3848C147.597 21.3848 149.932 19.5577 149.932 14.9221C149.932 11.5046 148.612 8.66234 145.702 8.66234C144.417 8.66234 143.3 9.40674 142.623 10.3542V20.3697C143.3 21.0465 144.383 21.3848 145.398 21.3848ZM145.263 27.7122V28.4228H137.649V27.7122C139.24 27.7122 139.646 27.5769 139.646 25.9189V10.1511C139.646 8.49316 139.24 8.29014 137.649 8.29014V7.57958H142.623V9.71127C143.435 8.49316 145.127 7.24121 147.022 7.24121C150.44 7.24121 152.977 10.0835 152.977 14.2453C152.977 19.287 149.424 22.3322 145.973 22.3322C144.654 22.3322 143.469 21.9262 142.623 21.2156V25.9189C142.623 27.5769 143.165 27.7122 145.263 27.7122Z"
          fill="#51A1EB"
        />
        <path
          d="M130.845 21.9941V18.0352C129.728 20.7422 127.292 22.3325 124.483 22.3325C120.118 22.3325 117.716 19.2195 117.716 14.1102V3.99315C117.716 2.40284 116.972 2.26749 115.043 2.26749V1.48926H123.502V2.26749C121.573 2.26749 120.829 2.40284 120.829 3.99315V14.0764C120.829 18.3059 122.453 20.7083 125.634 20.7083C128.07 20.7083 130.202 19.3549 130.845 16.2758V3.99315C130.845 2.40284 130.1 2.26749 128.171 2.26749V1.48926H136.631V2.26749C134.702 2.26749 133.958 2.40284 133.958 3.99315V19.4902C133.958 21.0805 134.702 21.2159 136.631 21.2159V21.9941H130.845Z"
          fill="#51A1EB"
        />
        <path
          d="M105.635 21.2833V21.9939H98.6309V21.2833C100.221 21.2833 100.627 21.148 100.627 19.49V10.0496C100.627 8.39165 100.221 8.29014 98.6309 8.29014V7.57958H103.605V10.388C104.248 9.06837 105.94 7.24121 108.24 7.24121C111.252 7.24121 112.639 9.47441 112.639 12.1813V19.49C112.639 21.148 113.045 21.2833 114.635 21.2833V21.9939H107.665V21.2833C109.255 21.2833 109.662 21.148 109.662 19.49V12.7565C109.662 10.2865 108.613 8.96687 106.65 8.96687C105.263 8.96687 104.112 9.94812 103.605 11.0647V19.49C103.605 21.148 104.045 21.2833 105.635 21.2833Z"
          fill="#51A1EB"
        />
        <path
          d="M93.3858 12.1137C93.3858 9.67743 92.2354 7.98561 90.5436 7.98561C88.8179 7.98561 87.6675 9.67743 87.6675 12.1137C87.6675 14.5837 88.8179 16.2755 90.5436 16.2755C92.2354 16.2755 93.3858 14.5837 93.3858 12.1137ZM97.9538 23.7195C97.9538 26.934 94.0626 28.7611 90.8481 28.7611C86.8216 28.7611 83.6748 27.3062 83.6748 24.8361C83.6748 23.0428 85.6373 21.7909 87.8028 21.5202C87.16 21.2156 86.5171 20.5727 86.5171 19.5577C86.5171 18.1704 87.5998 17.3245 88.9871 16.8846C86.5847 16.3094 84.8252 14.4822 84.8252 12.1137C84.8252 9.27139 87.4306 7.24121 90.5436 7.24121C91.288 7.24121 91.9986 7.34272 92.6753 7.57958C94.9085 7.57958 96.668 7.5119 98.0891 7.34272L97.5816 8.73001L94.604 8.66234C95.5852 9.50825 96.2281 10.7264 96.2281 12.1137C96.2281 14.9897 93.6227 17.0538 90.5436 17.0538C90.3406 17.0538 90.1714 17.0538 89.9684 17.0199C89.0548 17.1891 88.5472 17.5613 88.5472 18.1704C88.5472 18.6441 88.8856 18.9148 89.4947 19.0839L94.1979 20.1667C96.4988 20.6743 97.9538 21.757 97.9538 23.7195ZM93.4535 22.9751L88.5134 21.757C86.9231 22.0615 86.0095 23.1443 86.0095 24.6331C86.0095 26.731 87.972 27.9152 90.8481 27.9152C93.3182 27.9152 95.7206 27.0017 95.7206 25.1407C95.7206 24.1256 95.1453 23.3812 93.4535 22.9751Z"
          fill="#51A1EB"
        />
        <path
          d="M78.1141 2.60548C78.1141 1.59039 78.96 0.77832 79.9413 0.77832C80.9902 0.77832 81.8023 1.59039 81.8023 2.60548C81.8023 3.62058 80.9902 4.39881 79.9413 4.39881C78.96 4.39881 78.1141 3.62058 78.1141 2.60548ZM78.554 10.0495C78.554 8.39151 78.1479 8.29 76.5576 8.29V7.57943H81.5316V19.4898C81.5316 21.1478 81.9714 21.2832 83.5617 21.2832V21.9937H76.5576V21.2832C78.1479 21.2832 78.554 21.1478 78.554 19.4898V10.0495Z"
          fill="#51A1EB"
        />
        <path
          d="M68.0587 21.3507C70.3596 21.3507 72.3221 20.2003 72.3221 17.561C72.3221 15.6662 71.2055 14.7526 69.0738 13.6022L65.7579 11.8427C63.457 10.5907 61.9344 9.27112 61.9344 6.69956C61.9344 3.55277 64.6074 1.15039 68.0926 1.15039C70.4273 1.15039 71.7807 2.26699 72.4575 2.26699C72.9312 2.26699 73.1004 1.9963 73.3034 1.45492H73.7433L74.0139 7.44396L73.4049 7.47779C73.0327 4.36485 71.0025 2.13165 68.1603 2.13165C65.9609 2.13165 64.4721 3.65428 64.4721 5.48145C64.4721 7.5793 65.9609 8.39138 67.7542 9.33879L70.5965 10.8614C73.0327 12.1472 74.9275 13.5683 74.9275 16.2753C74.9275 19.7942 71.9161 22.332 68.1264 22.332C65.4872 22.332 64.1337 20.9785 63.3555 20.9785C62.9156 20.9785 62.6449 21.4522 62.4757 22.0613H61.9682L61.5283 15.2602L62.2389 15.2263C62.8479 19.0498 65.2842 21.3507 68.0587 21.3507Z"
          fill="#51A1EB"
        />
      </g>
      <defs>
        <clipPath id="clip0_1596_1729">
          <rect width="240" height="28.761" fill="white" />
        </clipPath>
        <clipPath id="clip1_1596_1729">
          <rect width="55.3284" height="20.492" fill="white" transform="translate(0 2.15381)" />
        </clipPath>
      </defs>
    </svg>
  );
};
