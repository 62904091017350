import psl from 'psl';
import {capitalizeFirstLetter} from '~/common/utils';
import {affiliateVendors} from '~/common/utils/affiliate_tags';

export const AffiliateIcons = new Map([
  [
    affiliateVendors.AMAZON,
    'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/81cfece92d5f4fe793d98fa25b99de9d.png?r=0.06251988008735476',
  ],
  [
    affiliateVendors.WALMART,
    'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/074e295b2d5e4ef1b2aafde5eb533943.png?r=0.2282967197435306',
  ],
  [
    affiliateVendors.TARGET,
    'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/17ce297f4075481bb63803e89a150acc.png?r=0.5258412927975611',
  ],
  [
    affiliateVendors.ETSY,
    'https://g0.evitecdn.com/pages/__gift_registry_vendor_images/5941379294363648/etsy_logo.svg',
  ],
]);

export const isValidAmazonAffiliateUrl = (product) =>
  product.vendor_name === 'amazon' && product.price;

export const generateDisplayText = (href) => {
  try {
    const url = new URL(href);
    const parsed = psl.parse(url.hostname);
    return parsed.sld ? capitalizeFirstLetter(parsed.sld) : '';
  } catch (err) {
    return '';
  }
};
