import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {Link} from '~/common/_pb_components/atoms/Link';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {useAffiliateActions} from '~/SignUpSheets/hooks/useAffiliateActions';
import {
  selectSheetId,
  selectSheetType,
  selectSignupOptions,
  selectSignups,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {BLUEPRINT_TYPE_ITEMS} from '~/SignUpSheets/reducers/blueprint/constants';
import {SignUpDayGroups} from './SignUpDayGroups/SignUpDayGroups';
import {SignUpItems} from '~/SignUpSheets/View/routes/VolunteerConfirm/components/SignUpContainer/SignUpItems/SignUpItems';

import './SignUpContainer.sass';

export const SignUpContainer = ({userId}) => {
  const {state: {isEditing} = {isEditing: false}, search} = useLocation();
  const signupOptions = useSelector(selectSignupOptions);
  const {linkPreviews} = useAffiliateActions();
  const blueprintType = useSelector(selectSheetType);
  const signups = useSelector(selectSignups);
  const sheetId = useSelector(selectSheetId);

  const orderedSignupIds = useMemo(() => signupOptions.map((s) => s.uuid), [signupOptions]);
  const sortedAndFilteredSignups = useMemo(
    () =>
      signups
        .filter((s) => !!s.user_id)
        .toSorted((a, b) => new Date(a.updated_at) - new Date(b.updated_at))
        .filter((s) => userId === s.user_id)
        .map((s) => ({
          ...s,
          product: linkPreviews[s.option],
        })),
    [signups, orderedSignupIds, userId, linkPreviews]
  );

  if (isEditing) {
    return null;
  }

  return (
    <div className="confirm-page__section">
      {blueprintType === BLUEPRINT_TYPE_ITEMS ? (
        <SignUpItems sortedAndFilteredSignups={sortedAndFilteredSignups} />
      ) : (
        <SignUpDayGroups userId={userId} sortedAndFilteredSignups={sortedAndFilteredSignups} />
      )}

      <Link
        as="secondary"
        data-qa-id="footer-view-sheet-link"
        href={sheetId ? `/signup-sheet/${[sheetId, search].join('/')}` : ''}
        variant="small"
        className="signup-container--view-sheet__button"
      >
        <Typography variant="paragraph3" semibold>
          View sheet
        </Typography>
      </Link>
    </div>
  );
};
