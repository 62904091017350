import React from 'react';
import {PB_SocialFb} from '~/common/svg/PB_SocialFb';
import {PB_SocialTwi} from '~/common/svg/PB_SocialTwi';
import {PB_SocialPin} from '~/common/svg/PB_SocialPin';
import {PB_SocialIg} from '~/common/svg/PB_SocialIg';
import {PB_SocialYt} from '~/common/svg/PB_SocialYt';
import {pebble} from '~sass/pb_styleguide/base/_exports.sass';

export const SocialButtons = () => (
  <ul className="social">
    <li>
      <a
        href="https://www.facebook.com/evite"
        title="Facebook"
        target="_blank"
        rel="noopener noreferrer"
        className="facebook"
        data-qa-id="footer_fb"
      >
        <PB_SocialFb color={pebble} ratio={0.75} />
      </a>
    </li>
    <li>
      <a
        href="https://twitter.com/evite"
        title="Twitter"
        target="_blank"
        rel="noopener noreferrer"
        className="twitter"
        data-qa-id="footer_twitter"
      >
        <PB_SocialTwi color={pebble} ratio={0.75} />
      </a>
    </li>
    <li>
      <a
        href="https://pinterest.com/evite/"
        title="Pinterest"
        target="_blank"
        rel="noopener noreferrer"
        className="pinterest"
        data-qa-id="footer_pinterest"
      >
        <PB_SocialPin color={pebble} ratio={0.75} />
      </a>
    </li>
    <li>
      <a
        href="https://instagram.com/evite/"
        title="Instagram"
        target="_blank"
        rel="noopener noreferrer"
        className="instagram"
        data-qa-id="footer_instagram"
      >
        <PB_SocialIg color={pebble} ratio={0.75} />
      </a>
    </li>
    <li>
      <a
        href="https://youtube.com/evite/"
        title="YouTube"
        target="_blank"
        rel="noopener noreferrer"
        className="youtube"
        data-qa-id="footer_youtube"
      >
        <PB_SocialYt color={pebble} ratio={0.75} />
      </a>
    </li>
  </ul>
);
