import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {ArrowIconReinvention} from '~/common/svg/ArrowReinvention';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {Button} from '~/common/_pb_components/atoms/Button';
import {pebble} from '~sass/pb_styleguide/base/_exports.sass';

export const resizeAccordion = (id, toggle = false) => {
  const accordionBodyWrapper = document.getElementById(id);
  const accordionBody = document.getElementById(`${id}_inner`);
  if (accordionBodyWrapper.clientHeight && toggle) {
    accordionBodyWrapper.style.height = 0;
  } else {
    accordionBodyWrapper.style.height = `${accordionBody.clientHeight}px`;
  }
};

export const Accordion = ({
  initialOpenState = true,
  accordionBodyWrapperId = '',
  onClose,
  onOpen,
  children,
  className = '',
  alwaysOpen = false,
  accordionButtonClassName = '',
  label = null,
  subLabel,
  labelDescription,
  additionalLabelContent = null,
  labelVariant = 'label3',
  subLabelVariant = 'header5',
  labelDescriptionVariant = 'paragraph3',
  labelClassName = '',
  labelDecoration = null,
  chevron = null,
  externalOpen = false,
  uniqueAccordionBodyWrapperId,
}) => {
  const [open, setOpen] = useState(initialOpenState);

  const bodyWrapperId =
    uniqueAccordionBodyWrapperId ??
    `${accordionBodyWrapperId}-${Math.floor(Math.random() * 999999) + 1}`;

  const handleGroupToggle = () => {
    resizeAccordion(bodyWrapperId, true);
    setOpen(!open);
    if (open && onClose) onClose();
    else if (onOpen) onOpen();
  };

  useEffect(() => {
    if (open) resizeAccordion(bodyWrapperId);
  }, [children, open]);

  useEffect(() => {
    if (externalOpen) {
      resizeAccordion(bodyWrapperId, !externalOpen);
      setOpen(true);
    }
  }, [externalOpen]);

  // can't use string concat - these are elements
  const labelToShow = labelDecoration ? (
    <>
      {label} {labelDecoration}
    </>
  ) : (
    label
  );

  return (
    <div className={`accordion__container ${className}`}>
      {!alwaysOpen && (
        <Button
          className={`accordion__button ${accordionButtonClassName}`}
          size="small"
          variant="transparent"
          data-qa-id={`accordion-show-hide-content_${label.replaceAll(' ', '_')}`}
          onClick={handleGroupToggle}
        >
          {subLabel || labelDescription || additionalLabelContent ? (
            <div className="accordion__label-wrapper">
              <Typography variant={labelVariant} className={labelClassName}>
                {labelToShow}
              </Typography>
              {subLabel && <Typography variant={subLabelVariant}>{subLabel}</Typography>}
              {labelDescription && (
                <Typography variant={labelDescriptionVariant} color="pebble">
                  {labelDescription}
                </Typography>
              )}
              {additionalLabelContent}
            </div>
          ) : (
            <Typography variant={labelVariant} className={labelClassName}>
              {labelToShow}
            </Typography>
          )}
          {chevron ?? (
            <ArrowIconReinvention direction={open ? 'down' : 'up'} ratio={0.5} color={pebble} />
          )}
        </Button>
      )}
      <div className="accordion__body-wrapper" id={bodyWrapperId}>
        <div id={`${bodyWrapperId}_inner`}>{children}</div>
      </div>
    </div>
  );
};

Accordion.propTypes = {
  accordionButtonClassName: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelVariant: PropTypes.string,
  labelClassName: PropTypes.string,
  labelDecoration: PropTypes.node,
  chevron: PropTypes.node,
  children: PropTypes.node.isRequired,
  alwaysOpen: PropTypes.bool,
  accordionBodyWrapperId: PropTypes.string,
  uniqueAccordionBodyWrapperId: (props, propName, componentName) => {
    if (!props.accordionBodyWrapperId && !props.uniqueAccordionBodyWrapperId)
      return new Error(
        `One of props 'accordionBodyWrapperId' or 'uniqueAccordionBodyWrapperId' was not specified in '${componentName}'.`
      );
    return undefined;
  },
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  className: PropTypes.string,
  initialOpenState: PropTypes.bool,
  externalOpen: PropTypes.bool,
};
