import React from 'react';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export const PB_Trash = (props) => {
  const {color = charcoal, className = '', ratio = 1} = props;

  // default dimensions
  const width = 16;
  const height = 16;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.332 4.00016H14.6654V5.3335H13.332V14.0002C13.332 14.177 13.2618 14.3465 13.1368 14.4716C13.0117 14.5966 12.8422 14.6668 12.6654 14.6668H3.33203C3.15522 14.6668 2.98565 14.5966 2.86063 14.4716C2.7356 14.3465 2.66536 14.177 2.66536 14.0002V5.3335H1.33203V4.00016H4.66536V2.00016C4.66536 1.82335 4.7356 1.65378 4.86063 1.52876C4.98565 1.40373 5.15522 1.3335 5.33203 1.3335H10.6654C10.8422 1.3335 11.0117 1.40373 11.1368 1.52876C11.2618 1.65378 11.332 1.82335 11.332 2.00016V4.00016ZM11.9987 5.3335H3.9987V13.3335H11.9987V5.3335ZM5.9987 2.66683V4.00016H9.9987V2.66683H5.9987Z"
        fill={color}
      />
    </svg>
  );
};
