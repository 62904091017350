export const isValidURL = (str) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?' +
      '(\\/[\\w%_.~+=-]*)*' +
      '(\\?.*)?$',
    'i'
  );
  return !!pattern.test(str);
};

const VALUE_ERROR_PREFIX = 'Value error, ';
const MAX_SLOTS_NUMBER = 1000;
const MAX_DAYS_NUMBER = 365;

export const parseErrors = (errors) => {
  const formattedErrors = [];
  if (typeof errors === 'string') {
    try {
      // Try to parse errors into an array and push to formattedErrors
      formattedErrors.push(...JSON.parse(errors));
    } catch (err) {
      // It's a regular non-array string, so just add the string to the formattedErrors array
      formattedErrors.push(errors);
    }
  } else {
    // It's already an array, so push the entire array to formattedErrors
    formattedErrors.push(...errors);
  }
  const savingErrors = formattedErrors.filter((e) => typeof e === 'string');
  const validationErrors = formattedErrors
    .filter((e) => typeof e !== 'string')
    .map((e) => ({
      ...e,
      msg: e.msg?.startsWith(VALUE_ERROR_PREFIX)
        ? e.msg.split(VALUE_ERROR_PREFIX).slice(1).join('')
        : e.msg,
    }));
  return {savingErrors, validationErrors};
};

export const isValidSlotsNumber = (num) => {
  const isValid = num <= MAX_SLOTS_NUMBER;
  return {
    isValid,
    message: !isValid ? `You've exceeded your ${MAX_SLOTS_NUMBER} slot limit` : null,
  };
};

export const isValidDaysNumber = (num) => {
  const isValid = num <= MAX_DAYS_NUMBER;
  return {isValid, message: !isValid ? `You've exceeded your ${MAX_DAYS_NUMBER} days limit` : null};
};
