import cx from 'classnames';
import {useSelector} from 'react-redux';
import {Link} from '~/common/_pb_components/atoms/Link';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {AffiliateIcons} from '~/SignUpSheets/utils/affiliate';
import {LinkPreview} from '~/SignUpSheets/components/LinkPreview/LinkPreview';
import {Comment} from '~/SignUpSheets/View/routes/VolunteerConfirm/components/SignUpContainer/Comment/Comment';
import {selectIsOrganizer} from '~/SignUpSheets/reducers/sheet/selectors';

// eslint-disable-next-line import/no-absolute-path
import AmazonIcon from '/assets/images/logos/amazon-logo-v2@2x.png';
import './Product.sass';

export const Product = ({count, product = {}, comment, className}) => {
  const isOrganizer = useSelector(selectIsOrganizer);

  if (!product.affiliate_url) {
    return null;
  }

  return (
    <div className={cx('signup__product', className)}>
      <div className="signup__product__upper">
        {product.affiliate_url &&
          (isOrganizer || !product.not_safe) &&
          (product.vendor_name ? (
            <img
              src={product.images?.primary?.medium?.url ?? AffiliateIcons.get(product.vendor_name)}
              className="signup__product__upper__image"
              alt={product.title ?? product.vendor_name}
              height={54}
              width={54}
            />
          ) : (
            <LinkPreview href={product.affiliate_url} displayText={product.display_text} />
          ))}

        <div className="signup__product__upper__info">
          {product.title && (
            <Typography
              variant="paragraph4"
              size="small"
              className="signup__product__upper__title"
              color="pebble"
            >
              {product.title}
            </Typography>
          )}

          <div className="signup__product__upper__info__count-price__wrapper">
            <div className="signup__product__upper__info__count-price">
              {count && (
                <Typography variant="paragraph4" size="small" color="charcoal">
                  Signup quantity: {count}
                </Typography>
              )}

              {product.price?.display_amount && (
                <Typography variant="paragraph4" color="charcoal">
                  {product.price.display_amount}
                </Typography>
              )}
            </div>

            {product.vendor_name === 'amazon' && (
              <img
                src={AmazonIcon}
                alt="Amazon"
                className="signup__product__upper__info__amazon-logo"
              />
            )}
          </div>

          <Comment comment={comment} />

          {product.vendor_name && (
            <Link
              href={product.affiliate_url}
              target="_blank"
              as="primary"
              variant="small"
              data-qa-id="purchase-now"
              className="signup__product__purchase-now__button"
            >
              Purchase now
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
