import {INITIAL_THEMES, SET_THEMES} from './constants';

const initialState = INITIAL_THEMES;

export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_THEMES:
      return [...(action.payload || state)];
    default:
      return state;
  }
};
