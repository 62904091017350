import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';

export const BlockThisHost = () => {
  const {isMobile} = evite.detects;
  const eventId = window.event_id || '';

  const {isHost, guestId} = useSelector(
    ({user: s}) => ({isHost: s.isHost, guestId: s.guestId}),
    shallowEqual
  );

  if (!guestId || isHost) return null;

  const hostUserId = useSelector((state) => state.eventDetails?.hostUserId ?? null);

  const url = `/profile/block_host/?host_user_id=${hostUserId}&event_id=${eventId}&gid=${guestId}`;

  return (
    <div className={`block-host-message ${isMobile ? 'mobile' : ''}`} id="block-host-message">
      Don't want to receive Evite communication from this person? <a href={url}>Block this host</a>
    </div>
  );
};
