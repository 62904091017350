import {useCallback} from 'react';
import {shallowEqual, useDispatch, useSelector, useStore} from 'react-redux';
import {selectSelectedSignups} from '~/SignUpSheets/reducers/sheet/selectors';
import {useRemoveSignupMutation} from '../api/sheet';

export const useRemoveSignup = (closeOverlay) => {
  const dispatch = useDispatch();
  const store = useStore();
  const {sheet} = store.getState();
  const selectedSignups = useSelector(selectSelectedSignups, shallowEqual);
  const [removeSignup, {isLoading}] = useRemoveSignupMutation();

  const handleRemoveSignup = useCallback(
    async (item) => {
      try {
        await removeSignup({id: sheet.id, row_id: item.id});

        const newSelectedSignups = selectedSignups.filter((s) => s.id !== item.id);

        if (closeOverlay && newSelectedSignups.length === 0) {
          closeOverlay();
        }
      } catch (error) {
        evite.error('Failed to remove signup:', error);
      }
    },
    [removeSignup, selectedSignups, sheet.id, dispatch, closeOverlay]
  );

  return [handleRemoveSignup, isLoading];
};
