import {useSelector} from 'react-redux';
import {useMatchQuery} from '~/common/hooks/useMatchQuery';
import {
  selectCalendarLinks,
  selectEndDate,
  selectSheetType,
  selectStartDate,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {formatDateRange} from '~/SignUpSheets/utils/misc';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {BLUEPRINT_TYPE_ITEMS} from '~/SignUpSheets/reducers/blueprint/constants';
import {AddToCalendar} from '~/common/_pb_components/molecules/AddToCalendar';

import {largishAndUpQuery} from '~sass/pb_styleguide/base/_exports.sass';

export const MoreDetails = () => {
  const blueprintType = useSelector(selectSheetType);
  const startDate = useSelector(selectStartDate);
  const endDate = useSelector(selectEndDate);
  const calendarLinks = useSelector(selectCalendarLinks);
  const {matches: isDesktop} = useMatchQuery(`(${largishAndUpQuery})`);

  if (blueprintType !== BLUEPRINT_TYPE_ITEMS) {
    return null;
  }

  return (
    <div className="confirm-page__section">
      <Typography
        className="confirm-page__title"
        variant={isDesktop ? 'header2' : 'header3'}
        as="h2"
      >
        More details
      </Typography>
      <Typography variant="paragraph1">{formatDateRange(startDate, endDate)}</Typography>

      <div className="confirm-page__add-to-calendar-wrapper">
        <AddToCalendar
          data-qa-id="add-to-calendar-link"
          closeMenu={() => {}}
          isButton
          googleCalUrl={calendarLinks.google_cal_url}
          iCalUrl={calendarLinks.ical_url}
          outlookUrl={calendarLinks.outlook_cal_url}
          type="signup-sheet"
          buttonSize="large"
        />
      </div>
    </div>
  );
};
