import cx from 'classnames';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {Button} from './Button';
import {useBrowserWidth} from '~/common/hooks';

export const Toggle = ({className, bgClassName, options = [], onChange, value}) => {
  const [backgroundStyle, setBackgroundStyle] = useState({});
  const browserWidth = useBrowserWidth();

  const updateBackgroundStyle = useCallback(() => {
    const activeButtonRef = options.find((o) => o.value === value)?.ref;
    if (activeButtonRef?.current) {
      const {offsetWidth, offsetLeft} = activeButtonRef.current;
      setBackgroundStyle({
        width: offsetWidth,
        transform: `translateX(${offsetLeft}px)`,
      });
    }
  }, [options]);

  useEffect(() => {
    updateBackgroundStyle();
  }, [updateBackgroundStyle, browserWidth]);

  return (
    <div className={cx('toggle', {[className]: !!className})}>
      <div
        className={cx('toggle__background', {[bgClassName]: !!bgClassName})}
        style={backgroundStyle}
      />
      {options.map((o) => (
        <Button
          key={o.value.toString()}
          variant="unstyled-button"
          className={cx('toggle__option', o.className, {
            selected: o.value === value,
          })}
          onClick={() => onChange(o.value)}
          data-qa-id={o.dataQaId}
          ref={o.ref}
        >
          {o.icon}
          {o.label}
        </Button>
      ))}
    </div>
  );
};

Toggle.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
      dataQaId: PropTypes.string.isRequired,
      className: PropTypes.string,
      icon: PropTypes.node,
    })
  ).isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
};
