import React, {useEffect} from 'react';
import {MegaNav} from '~/MegaNav/MegaNav';

/** *
 *
 * This wrapper component allows for the MegaNav to be added into specific pages
 * of apps that wouldn't normally include the MegaNav, such as the Fabric Create Flow's Confirmation page.
 *
 * It requires that the app's index.html file include this:
 *  {% include 'nav.html' %}
 *
 *  ** */

export const InjectableMegaNav = () => {
  useEffect(() => {
    document.getElementById('pbMegaNav')?.classList.remove('hidden');

    return () => {
      document.getElementById('pbMegaNav')?.classList.add('hidden');
    };
  }, []);

  return (
    <>
      <div className="injectable-header">
        <MegaNav />
      </div>
      <div id="fixed-header-spacer" />
    </>
  );
};
