import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {useMatchQuery} from '~/common/hooks/useMatchQuery';
import {selectSheetId} from '~/SignUpSheets/reducers/sheet/selectors';
import {Link} from '~/common/_pb_components/atoms/Link';
import {Typography} from '~/common/_pb_components/atoms/Typography';

import {largishAndUpQuery} from '~sass/pb_styleguide/base/_exports.sass';

export const Header = () => {
  const {state: {isEditing} = {isEditing: false}, search} = useLocation();
  const sheetId = useSelector(selectSheetId);
  const {matches: isDesktop} = useMatchQuery(`(${largishAndUpQuery})`);

  return (
    <div className="confirm-page__header">
      <Typography variant={isDesktop ? 'header1' : 'header2'} as="h1">
        {isEditing ? 'Your changes are saved!' : "You're all signed up!"}
      </Typography>
      {isEditing && (
        <Link
          variant="small"
          data-qa-id="view-sheet-link"
          href={sheetId ? `/signup-sheet/${[sheetId, search].join('/')}` : ''}
        >
          View Sheet
        </Link>
      )}
    </div>
  );
};
