import {useSelector} from 'react-redux';
import {useAffiliateActions} from '~/SignUpSheets/hooks/useAffiliateActions';
import {selectSignupOptions} from '~/SignUpSheets/reducers/sheet/selectors';
import {SignUpDay} from './SignUpDay';

export const SignUpDayGroups = ({sortedAndFilteredSignups}) => {
  const {linkPreviews} = useAffiliateActions();
  const signupOptions = useSelector(selectSignupOptions);

  const currentUserSignupsIds = sortedAndFilteredSignups.map((el) => el.signup_option);
  const currentUserSignupsSlotsIds = sortedAndFilteredSignups.map((el) => el.slot_id);

  const onlySignedUpByCurrentUser = signupOptions
    .filter((signupOption) => currentUserSignupsIds.includes(signupOption.uuid))
    .map((signupOption) => ({
      ...signupOption,
      slots:
        signupOption.slots
          .filter((slot) => currentUserSignupsSlotsIds.includes(slot.slot_id))
          .map((slot) => {
            const signedUps = sortedAndFilteredSignups.filter((s) => s.slot_id === slot.slot_id);

            return {
              ...slot,
              comment: signedUps[0].comment || '',
              calendarLinks: signedUps[0].calendar_links || {},
              count: signedUps.length,
              product: {
                ...(slot.product_link ?? {}),
                ...linkPreviews[slot.slot_id || slot.uuid],
              },
            };
          }) || [],
    }));

  return onlySignedUpByCurrentUser.map((signedUp) => (
    <SignUpDay
      key={signedUp.uuid}
      date={signedUp.date}
      title={signedUp.title}
      description={signedUp.description}
      slots={signedUp.slots}
      signedUp={signedUp}
    />
  ));
};
