import cx from 'classnames';
import {useCallback, useContext, useMemo, useRef, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {
  selectIsInEditMode,
  selectIsOrganizer,
  selectSignups,
  selectTitle,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {Button} from '~/common/_pb_components/atoms/Button';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {MoreIcon} from '~/common/svg/MoreIcon';
import {PB_EditLine as EditIcon} from '~/common/svg/PB_EditLine';
import {PB_LinkIcon as LinkIcon} from '~/common/svg/PB_LinkIcon';
import {PB_Trash as TrashIcon} from '~/common/svg/PB_Trash';
import {PB_ClickOutLine as ClickOutLine} from '~/common/svg/PB_ClickOutLine';
import {ContentBase} from '~/CreateFabricInvitation/routes/EventCreateDesign/components/CardEditor/Mobile/Contents/ContentBase';
import {useOutsideAlerter} from '~/CreateFabricInvitation/routes/EventCreateDesign/hooks/useOutsideAlerter';
import {Spinner} from '~/common/_pb_components/atoms/Spinner';
import {useSheetActions} from '~/SignUpSheets/hooks/useSheetActions';
import {SignUpSheetContext} from '~/SignUpSheets/context/SignUpSheetContext';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';
import {emailSheetVolunteers} from '~/SignUpSheets/utils/misc';

import './HeaderOptions.sass';

export const HeaderOptions = ({handleEdit, handleShare, handleCancel, expired}) => {
  const {flow} = useContext(SignUpSheetContext);
  const {isSavingSheet, isSaveSuccess, isSaveError, autoSave} = useSheetActions();
  const [showMenu, setShowMenu] = useState(false);
  const ref = useRef();
  const isOrganizer = useSelector(selectIsOrganizer);
  const isInEditMode = useSelector(selectIsInEditMode);
  const signups = useSelector(selectSignups, shallowEqual);
  const sheetTitle = useSelector(selectTitle, shallowEqual);

  const toggleShowMenu = useCallback(() => setShowMenu((prevState) => !prevState), []);

  useOutsideAlerter(ref, () => setShowMenu(false));

  const onSaveHandler = useCallback(async () => {
    toggleShowMenu();
    await autoSave();
  }, [autoSave, toggleShowMenu]);

  const options = useMemo(() => {
    if (expired) return [];

    const opts = [];
    if (isOrganizer && flow === 'view') {
      opts.push(
        <Button
          variant="transparent"
          key="copy-shareable-link"
          data-qa-id="copy-shareable-link"
          onClick={handleShare}
          disableProcessing
          className="signup-sheet__header__action-button"
        >
          <LinkIcon />
          <Typography variant="paragraph2">Copy shareable link</Typography>
        </Button>,
        <Button
          variant="transparent"
          key="email-sheet-volunteers"
          data-qa-id="email-sheet-volunteers"
          onClick={() => emailSheetVolunteers({signups, title: sheetTitle})}
          disableProcessing
          className="signup-sheet__header__email-button"
        >
          <ClickOutLine ratio={0.78} />
          <Typography variant="paragraph2">Email volunteers</Typography>
        </Button>,
        <Button
          variant="transparent"
          key="edit-sheet"
          data-qa-id="edit-sheet"
          onClick={handleEdit}
          disableProcessing
        >
          <EditIcon ratio={0.75} />
          <Typography variant="paragraph2">Edit sheet</Typography>
        </Button>,
        <Button
          variant="transparent"
          key="cancel-sheet"
          data-qa-id="cancel-sheet"
          onClick={handleCancel}
          disableProcessing
        >
          <TrashIcon ratio={1.5} />
          <Typography variant="paragraph2">Cancel sheet</Typography>
        </Button>
      );
    }
    return opts;
  }, [
    isOrganizer,
    isInEditMode,
    flow,
    onSaveHandler,
    handleEdit,
    handleShare,
    handleCancel,
    signups,
    sheetTitle,
  ]);

  if (options.length === 0) {
    return null;
  }

  return (
    <>
      {showMenu && <div className="header-options" />}
      <div
        className={cx(`header-options-overlay__content`, {
          'header-options-overlay__content--active': showMenu,
        })}
        ref={ref}
      >
        <ContentBase
          resetDataQaId="sheet-options-reset"
          closeDataQaId="sheet-options-close"
          title="SignUp Sheet Options"
          visible={showMenu}
          hideResetButton
          onCloseClick={toggleShowMenu}
        >
          <div className="header-options__items">{options}</div>
        </ContentBase>
      </div>
      <Button
        data-qa-id="dots-btn"
        variant="transparent"
        size="large"
        onClick={toggleShowMenu}
        className="dots-btn"
      >
        <MoreIcon
          className={cx({
            'dots-icon--loading': isSavingSheet,
            'dots-icon--success': !isSavingSheet && isSaveSuccess,
            'dots-icon--error': !isSavingSheet && isSaveError,
          })}
          color={charcoal}
        />
        <Spinner isLoading={isSavingSheet} isSuccess={isSaveSuccess} isError={isSaveError} />
      </Button>
    </>
  );
};
