import {shallowEqual, useSelector} from 'react-redux';
import {selectAddress} from '~/SignUpSheets/reducers/sheet/selectors';

export const useFormattedAddress = ({addStreet = true, addCity = true, addState = true} = {}) => {
  const address = useSelector(selectAddress, shallowEqual);
  const cityState = [
    addStreet && address.street,
    addCity && address.city,
    addState && address.state,
  ]
    .filter(Boolean)
    .join(', ');
  return [cityState, address.zip].filter(Boolean).join(' ');
};
