import {useCallback, useContext, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {pluralize} from '~/common/utils';
import {useIsLargishAndUp, useIsSmallAndUp} from '~/common/hooks/useMatchQuery';
import {SignUpSheetContext} from '~/SignUpSheets/context/SignUpSheetContext';
import {SET_SELECTED_SIGNUPS, SET_SIGNUPS} from '~/SignUpSheets/reducers/sheet/constants';
import {
  selectExpired,
  selectIsOrganizer,
  selectSheetStatus,
  selectSignups,
} from '~/SignUpSheets/reducers/sheet/selectors';
import {useRemoveSignup} from '~/SignUpSheets/hooks/useRemoveSignup';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {Button} from '~/common/_pb_components/atoms/Button';
import {PB_Trash as TrashIcon} from '~/common/svg/PB_Trash';
import {PB_EnvelopeSent as EnvelopeSent} from '~/common/svg/PB_EnvelopeSent';
import {ResponsiveModal} from '~/common/_pb_components/organisms/ResponsiveModal';

import {pebble} from '~sass/pb_styleguide/base/_exports.sass';
import './FilledSlot.sass';

export const FilledSlot = ({
  userId,
  name,
  comment,
  email,
  signupsCount,
  matchingUser,
  showCommentOnMobile,
  slotId,
  signUpOptionId,
}) => {
  const [showConfirmDeleteVolunteer, setShowConfirmDeleteVolunteer] = useState(false);
  const {flow} = useContext(SignUpSheetContext);
  const isOrganizer = useSelector(selectIsOrganizer);
  const isLargishAndUp = useIsLargishAndUp();
  const isSmallAndUp = useIsSmallAndUp();
  const queryMatches = showCommentOnMobile ? isSmallAndUp : isLargishAndUp;
  const signups = useSelector(selectSignups);
  const dispatch = useDispatch();
  const [handleRemoveSignup] = useRemoveSignup();
  const past = useSelector((s) => selectExpired(s, {uuid: slotId || signUpOptionId}));
  const status = useSelector(selectSheetStatus);
  const canceled = status === 'canceled';
  const expired = useMemo(() => past || canceled, [past, canceled]);

  const handleTrashCanClick = useCallback(() => {
    const updatedSignups = signups.map((su) => {
      let isDeleted = false;

      const isMatchingSignup =
        (su.signup_option && su.signup_option === signUpOptionId && su.user_id === userId) ||
        (su.slot_id && su.slot_id === slotId && su.user_id === userId);

      if (isMatchingSignup) {
        if (su.id) {
          // If it contains an ID, it's a saved signup, so remove it from the backend
          handleRemoveSignup(su);
        }
        isDeleted = true;
      }

      return {...su, deleted: isDeleted};
    });

    const signupsToKeep = updatedSignups.filter((su) => !su.deleted);

    dispatch({type: SET_SIGNUPS, payload: signupsToKeep});
    dispatch({type: SET_SELECTED_SIGNUPS, payload: signupsToKeep});
  }, [handleRemoveSignup, dispatch, signups, userId, slotId, signUpOptionId]);

  return (
    <div className="filled-slot__container">
      <div className="filled-slot__item">
        <div
          className="filled-slot__name-comment-wrapper"
          data-qa-id="signup-row-user"
          data-qa-value={name}
        >
          <div className="filled-slot__name-row">
            <div>
              <Typography variant="list2" size="large" as="span" data-qa-id="signup-row-name">
                {name}
              </Typography>
              {matchingUser && (
                <Typography variant="paragraph2" as="span">
                  {' '}
                  (you)
                </Typography>
              )}
            </div>

            <div className="filled-slot__count-and-delete">
              <Typography color="shadow" variant="list1" size="small">
                {pluralize('slot', signupsCount)}
              </Typography>
              {flow === 'edit' && isOrganizer && !expired ? (
                <Button
                  variant="transparent"
                  data-qa-id="filled-slot__delete-btn"
                  onClick={setShowConfirmDeleteVolunteer}
                  aria-label={`Delete ${pluralize('signup', signupsCount)} for ${name}`}
                  className="filled-slot__delete-btn"
                >
                  <TrashIcon color={pebble} />
                </Button>
              ) : null}
            </div>
          </div>
          <div className="filled-slot__email-and-icon">
            {isOrganizer && email ? (
              <>
                <EnvelopeSent color={pebble} ratio={0.35} />
                <Typography
                  variant="list1"
                  color="pebble"
                  data-qa-id="signup-row-email"
                  data-qa-value={email}
                >
                  {email}
                </Typography>
              </>
            ) : null}
          </div>
          {comment && queryMatches && (
            <Typography
              variant="paragraph3"
              size="large"
              color="pebble"
              data-qa-id="signup-row-comment"
              data-qa-value={comment}
            >
              &quot;{comment}&quot;
            </Typography>
          )}
        </div>
      </div>
      {showConfirmDeleteVolunteer && (
        <ResponsiveModal
          isOpen
          title={`Are you sure you want to remove ${name} from this slot?`}
          primaryText="Yes, remove them"
          primaryAction={handleTrashCanClick}
          secondaryText="No, don't remove them"
          secondaryAction={() => {
            setShowConfirmDeleteVolunteer(false);
          }}
          onClose={() => setShowConfirmDeleteVolunteer(false)}
          body={`Once you remove ${name}, we’ll notify them via email.`}
          equalButtons
        />
      )}
    </div>
  );
};
