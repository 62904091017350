import {useEffect, useState, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {generateMd5Hash, generateSha256Hash} from '~/common/utils/hash';
import {cleanupROKTOverlay, triggerROKTOverlay} from '~/common/utils/ROKT';
import {selectVolunteerEmail} from '~/common/fabric/User/reducers/user';

import {SignUpContainer} from './components/SignUpContainer/SignUpContainer';
import {WishList} from './components/WishList';
import {Header} from './components/Header';
import {MoreDetails} from './components/MoreDetails';
import {selectSheetId} from '~/SignUpSheets/reducers/sheet/selectors';

import '~/SignUpSheets/Create/routes/HostConfirm/HostConfirm.sass';
import './VolunteerConfirm.sass';

export const VolunteerConfirm = () => {
  const {search} = useLocation();
  const volunteerEmail = useSelector(selectVolunteerEmail);
  const sheetId = useSelector(selectSheetId);

  const {
    userId: loggedInUserId,
    emailMd5: stateEmailMd5,
    emailSha256: stateEmailSha256,
  } = useSelector((state) => state?.user);

  const [emailMd5, setEmailMd5] = useState(stateEmailMd5);
  const [emailSha256, setEmailSha256] = useState(stateEmailSha256);

  const userId = atob(new URLSearchParams(search).get('uid')) || loggedInUserId;
  const roktEmail = volunteerEmail?.toLowerCase();

  const roktAttributes = {email: roktEmail, userId: loggedInUserId, emailMd5, emailSha256};

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Fetches and sets the MD5 and SHA-256 hashes for the email
  useEffect(() => {
    const fetchHashes = async () => {
      const md5 = loggedInUserId ? stateEmailMd5 : generateMd5Hash(roktEmail);
      const sha256 = loggedInUserId ? stateEmailSha256 : await generateSha256Hash(roktEmail);
      setEmailMd5(md5);
      setEmailSha256(sha256);
    };
    fetchHashes();
  }, [loggedInUserId, roktEmail, stateEmailMd5, stateEmailSha256]);

  // Triggers ROKT overlay when email hashes are available
  useEffect(() => {
    if (emailMd5 && emailSha256) {
      triggerROKTOverlay('signupsheet', roktAttributes);
      return cleanupROKTOverlay;
    }
    return () => {};
  }, [emailMd5, emailSha256]);

  const volViewHref = useMemo(
    () => (sheetId ? `/signup-sheet/${[sheetId, search].join('/')}` : ''),
    [sheetId]
  );

  return (
    <div className="sign-up-sheets__confirm-page">
      <Header />

      <SignUpContainer userId={userId} volViewHref={volViewHref} />

      <WishList />

      <MoreDetails />
    </div>
  );
};
