import moment from 'moment-timezone';

import {
  selectMultipleDatesModalStartDate,
  selectMultipleDatesModalEndDate,
  selectMultipleDatesModalFrequency,
  selectMultipleDatesModalDays,
  selectMultipleDatesModalMonthlyScheduleSpecifierOrdinal,
  selectMultipleDatesModalMonthlyScheduleSpecifierDay,
  selectMultipleDatesModalWeeklyScheduleSpecifier,
} from '~/SignUpSheets/reducers/sheet/selectors';

const getDailyRepeatsOnSelectedDays = (startDate, endDate, selectedDays) => {
  const start = moment(startDate).startOf('day');
  const end = moment(endDate).startOf('day');
  const dates = [];

  while (start.isSameOrBefore(end)) {
    if (selectedDays.includes(start.day().toString())) {
      dates.push(start.format('YYYY-MM-DD'));
    }
    start.add(1, 'day');
  }

  return dates;
};

const getWeeklyRepeatsOnSelectedDays = (startDate, endDate, selectedDays, interval) => {
  const start = moment(startDate).startOf('day');
  const end = moment(endDate).startOf('day');
  const dates = [];

  while (start.isSameOrBefore(end)) {
    selectedDays.forEach((day) => {
      const currentWeekDay = start.clone().day(day);
      // Adjust the currentWeekDay if it's before the start date
      if (currentWeekDay.isBefore(start, 'day')) {
        currentWeekDay.add(1, 'week');
      }
      if (currentWeekDay.isSameOrBefore(end)) {
        dates.push(currentWeekDay.format('YYYY-MM-DD'));
      }
    });
    start.add(interval, 'weeks');
  }

  return dates;
};

const getMonthlyRepeatsOnSelectedOrdinalAndDay = (startDate, endDate, ordinal, dayOfWeek) => {
  const start = moment(startDate).startOf('day');
  const end = moment(endDate).startOf('day');
  const dates = [];

  const ordinalMap = {
    '1st': 1,
    '2nd': 2,
    '3rd': 3,
    '4th': 4,
    last: 'last',
    'first day of month': 'firstDay',
    'last day of month': 'lastDay',
  };

  const targetOrdinal = ordinalMap[ordinal];
  const dayOffset = parseInt(dayOfWeek, 10);

  while (start.isSameOrBefore(end)) {
    const monthStart = moment(start).startOf('month');
    const monthEnd = moment(start).endOf('month');

    let date;

    if (targetOrdinal === 'firstDay') {
      date = monthStart.clone();
    } else if (targetOrdinal === 'lastDay') {
      date = monthEnd.clone();
    } else if (targetOrdinal === 'last') {
      // Find the last occurrence of the specified day of the week
      for (let d = monthEnd.clone(); d.isSameOrAfter(monthStart); d.subtract(1, 'day')) {
        if (d.day() === dayOffset) {
          date = d.clone();
          break;
        }
      }
    } else {
      let count = 0;
      for (let d = monthStart.clone(); d.isSameOrBefore(monthEnd); d.add(1, 'day')) {
        if (d.day() === dayOffset) {
          count++;
          if (count === targetOrdinal) {
            date = d.clone();
            break;
          }
        }
      }
    }

    if (date && date.isSameOrAfter(start) && date.isSameOrBefore(moment(end).endOf('day'))) {
      dates.push(date.format('YYYY-MM-DD'));
    }

    start.add(1, 'month').startOf('month');
  }

  return dates;
};

export const selectedDayHandler = (state, newState) => {
  const frequency = selectMultipleDatesModalFrequency(state);
  const newFrequency = selectMultipleDatesModalFrequency(newState);
  const newDays = selectMultipleDatesModalDays(newState);
  const startDate = selectMultipleDatesModalStartDate(state);
  const newStartDate = selectMultipleDatesModalStartDate(newState);

  if (newFrequency === 'daily') {
    return ['0', '1', '2', '3', '4', '5', '6'];
  }

  if (frequency === 'daily' && newFrequency !== 'daily') {
    return [moment(newStartDate).day().toString()];
  }

  if (startDate !== newStartDate) {
    return [moment(newStartDate).day().toString()];
  }

  if (newDays?.length === 0) {
    return [moment(newStartDate).day().toString()];
  }

  return [...new Set([...newDays])];
};

export const frequencyHandler = (state) => {
  const endDate = selectMultipleDatesModalEndDate(state);

  if (endDate === null) {
    return [];
  }

  const startDate = selectMultipleDatesModalStartDate(state);
  const frequency = selectMultipleDatesModalFrequency(state);
  const selectedDays = selectMultipleDatesModalDays(state);

  const weeklyScheduleSpecifier = selectMultipleDatesModalWeeklyScheduleSpecifier(state);

  const monthlyScheduleSpecifierOrdinal =
    selectMultipleDatesModalMonthlyScheduleSpecifierOrdinal(state);
  const monthlyScheduleSpecifierDay = selectMultipleDatesModalMonthlyScheduleSpecifierDay(state);

  if (frequency === 'daily') {
    return getDailyRepeatsOnSelectedDays(startDate, endDate, selectedDays);
  }

  if (frequency === 'weekly') {
    return getWeeklyRepeatsOnSelectedDays(
      startDate,
      endDate,
      selectedDays,
      weeklyScheduleSpecifier
    );
  }

  if (frequency === 'every other week') {
    return getWeeklyRepeatsOnSelectedDays(startDate, endDate, selectedDays, 2);
  }

  if (frequency === 'monthly') {
    return getMonthlyRepeatsOnSelectedOrdinalAndDay(
      startDate,
      endDate,
      monthlyScheduleSpecifierOrdinal,
      monthlyScheduleSpecifierDay
    );
  }

  return null;
};
