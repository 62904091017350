import React from 'react';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export const PB_MessageLine = (props) => {
  const {color = charcoal, className = '', ratio = 1} = props;

  // default dimensions
  const width = 32;
  const height = 32;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.684 22.6667H26.6667V6.66667H5.33333V24.5133L7.684 22.6667ZM8.60666 25.3333L2.66666 30V5.33333C2.66666 4.97971 2.80714 4.64057 3.05719 4.39052C3.30724 4.14048 3.64638 4 4 4H28C28.3536 4 28.6928 4.14048 28.9428 4.39052C29.1929 4.64057 29.3333 4.97971 29.3333 5.33333V24C29.3333 24.3536 29.1929 24.6928 28.9428 24.9428C28.6928 25.1929 28.3536 25.3333 28 25.3333H8.60666Z"
        fill={color}
      />
    </svg>
  );
};
