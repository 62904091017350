import {renderToStaticMarkup} from 'react-dom/server';
import parseHTML from 'html-react-parser';

export const affiliateVendors = {
  AMAZON: 'amazon',
  WALMART: 'walmart',
  TARGET: 'target',
  ETSY: 'etsy',
};
const WALMART_AFFILIATE_URL =
  'https://goto.walmart.com/c/3858077/565706/9383?veh=aff&sourceid=imp_000011112222333344';
const TARGET_AFFILIATE_URL =
  'https://goto.target.com/c/2310483/81938/2092?subId1=A&subId2=B&subId3=C&sharedid=D&partnerpropertyid=2052984';
const ETSY_AFFILIATE_URL = 'https://www.awin1.com/cread.php?awinmid=6220&awinaffid=1238431';

export function addAmazonAffiliateTag(url, params) {
  const urlObj = new URL(url);
  Object.entries(params).forEach(([key, value]) => {
    urlObj.searchParams.set(key, value);
  });
  return urlObj.toString();
}

export function extractHtmlATags(htmlString) {
  const regex = /<a[^>]*>([^<]+)<\/a>/gi;
  return htmlString.match(regex);
}

export function addAffiliateTag(url, affiliateUrl, tag = 'u') {
  const urlObj = new URL(affiliateUrl);
  urlObj.searchParams.set(tag, url);
  return urlObj.toString();
}

const affiliatesTags = {
  [affiliateVendors.AMAZON]: addAmazonAffiliateTag,
  [affiliateVendors.WALMART]: (url) => addAffiliateTag(url, WALMART_AFFILIATE_URL),
  [affiliateVendors.TARGET]: (url) => addAffiliateTag(url, TARGET_AFFILIATE_URL),
  [affiliateVendors.ETSY]: (url) => addAffiliateTag(url, ETSY_AFFILIATE_URL, 'ued'),
};

export function getURLOwner(url) {
  const domainToOwner = {
    'amazon.com': affiliateVendors.AMAZON,
    'amazon.ca': affiliateVendors.AMAZON,
    'amzn.com': affiliateVendors.AMAZON,
    'walmart.com': affiliateVendors.WALMART,
    'target.com': affiliateVendors.TARGET,
    'etsy.com': affiliateVendors.ETSY,
  };

  const regex = /https?:\/\/(www\.)?(amazon\.com|amazon\.ca|amzn\.com|walmart\.com|target\.com)/;
  const match = url.match(regex);

  if (match) {
    return domainToOwner[match[2]];
  }

  return null;
}

export const putAffiliateTagsToUrl = (url, params = {}) => {
  const owner = getURLOwner(url);
  if (!owner) return url;
  return affiliatesTags[owner](url, params);
};

export function putAffiliateTags(html, params = {}) {
  let htmlString = renderToStaticMarkup(html);
  const aTags = extractHtmlATags(htmlString);
  if (!aTags) {
    return html;
  }

  aTags.forEach((aTag) => {
    const url = aTag.match(/href="([^"]*)"/)[1];
    const owner = getURLOwner(url);
    if (owner) {
      const newUrl = affiliatesTags[owner](url, params);
      const hrefRegex = new RegExp(`href="${url.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')}"`, 'g');
      htmlString = htmlString.replace(hrefRegex, `href="${newUrl}"`);
    }
  });

  return parseHTML(htmlString);
}
