import cx from 'classnames';
import {PB_MessageLine as PBMessageLine} from '~/common/svg/PB_MessageLine';
import {Typography} from '~/common/_pb_components/atoms/Typography';

import {pebble} from '~sass/pb_styleguide/base/_exports.sass';
import './Comment.sass';

export const Comment = ({comment, className}) =>
  comment && (
    <div className={cx('signup-comment', className)}>
      <PBMessageLine className="signup-comment__icon" color={pebble} ratio={0.5} />
      <Typography variant="paragraph4" color="pebble">
        {`You commented: ”${comment}”`}
      </Typography>
    </div>
  );
