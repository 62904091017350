let promiseListeners = [];

export const reduxPromise = (executor, successActionTypes, errorActionTypes) => {
  let resolve;
  let reject;
  let completed = false;
  successActionTypes = successActionTypes || [];
  errorActionTypes = errorActionTypes || [];
  const promise = new Promise((resolve_, reject_) => {
    resolve = resolve_;
    reject = reject_;
    const actionListener = (action) => {
      // returns false if it should be removed from the list
      if (completed) return false;
      if (successActionTypes.indexOf(action.type) >= 0) {
        resolve();
        return false;
      }
      if (errorActionTypes.indexOf(action.type) >= 0) {
        reject();
        return false;
      }
      return true;
    };
    promiseListeners.push(actionListener);
    executor && executor(resolve_, reject_);
  });
  promise.finally(() => {
    completed = true;
  });
  return promise;
};

export const promiseResolverMiddleware = (store) => (next) => (action) => {
  next(action);
  promiseListeners = promiseListeners.filter((actionListener) => actionListener(action));
};
