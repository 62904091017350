import React from 'react';
import urls from 'urls';

/*
 * When shown on desktop, both buttons show and link to the /mobileapps page
 * If shown on a mobile device, only the appropriate app store button will show
 *  */

export const MobileApps = () => {
  const {isMobile} = evite.detects;
  const {isIOS} = evite.detects;

  const appLandingForWeb = urls.get('apps_landing_page');
  const googleUrl = !isIOS && isMobile ? urls.get('google_app_link_footer') : appLandingForWeb;
  const iosUrl =
    isIOS && isMobile
      ? urls.get('apple_store_app_link_footer', {
          referrer: 'MW_Reinvention_Footer',
        })
      : appLandingForWeb;

  const appStores = [
    {
      id: 'google',
      title: 'Android',
      url: googleUrl,
      alt: 'Evite App on Google Play',
      src: urls.get('google_app_icon'),
      show: !isMobile || (isIOS && isMobile),
    },
    {
      id: 'apple',
      title: 'iTunes',
      url: iosUrl,
      alt: 'Available on the App Store',
      src: urls.get('apple_store_app_icon'),
      show: !isMobile || (!isIOS && isMobile),
    },
  ];
  const buttonsToShow = appStores.filter((a) => a.show);

  return (
    <ul className="apps">
      {buttonsToShow.map((a, i) => (
        <li key={`${a.id}-${i}`}>
          <a
            href={a.url}
            title={a.title}
            data-qa-id={`footer_${a.id}_download`}
            target="_blank"
            rel="noreferrer"
          >
            <img src={a.src} alt={a.alt} loading="lazy" />
          </a>
        </li>
      ))}
    </ul>
  );
};
