import React from 'react';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export const Google = (props) => {
  const {className = '', ratio = 1} = props;

  // default dimensions
  const width = 32;
  const height = 32;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 16.482C28 23.441 23.2344 28.3934 16.1967 28.3934C9.44918 28.3934 4 22.9443 4 16.1967C4 9.44918 9.44918 4 16.1967 4C19.482 4 22.2459 5.20492 24.3754 7.1918L21.0557 10.3836C16.7131 6.19344 8.63771 9.34098 8.63771 16.1967C8.63771 20.4508 12.0361 23.8984 16.1967 23.8984C21.0262 23.8984 22.8361 20.4361 23.1213 18.641H16.1967V14.4459H27.8082C27.9213 15.0705 28 15.6705 28 16.482Z"
        fill={charcoal}
      />
    </svg>
  );
};
