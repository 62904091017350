import {Footer} from '~/footer/Footer';

/** *
 *
 * This wrapper component allows for the Site Footer to be added into specific pages
 * of apps that wouldn't normally include it, such as the Fabric Create Flow's Confirmation page.
 *
 * To show an ad, the app's index.html file needs to include this:
 * <script type="text/javascript">
 *  window.footerAd = {{ render_ad(request, 'ad-mobile-bottom') }};
 * </script>
 *  ** */

export const InjectableFooter = ({showAd}) => {
  const adToShow = window.footerAd;

  if (adToShow) {
    adToShow.setAttribute('padding-bottom', '60px');
  }

  return (
    <>
      <div className="footer-wrap old-evite-footer">
        <div id="footer" className="evt-footer" role="contentinfo">
          {showAd && adToShow}
          <Footer />
        </div>
      </div>
      <div id="feedback" />
    </>
  );
};
