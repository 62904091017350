import DOMPurify from 'dompurify';
import linkifyHtml from 'linkify-html';
import parseHTML from 'html-react-parser';

/**
 * Parses a string to contain HTML escape characters.
 *
 * @param {Object} unsafe - The string to add escape chars to.
 */
export function escapeHtml(unsafe) {
  return unsafe
    ?.replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
}

/**
 * Sanitizes a string by escaping HTML chars and returns a newly parsed element.
 * Relevant to protect against cross-site scripting.
 *
 * @param {Object} inputString - The string to sanitize.
 * @param {string} parse - Whether or not to parse the sanitized output as HTML nodes
 */
export function sanitizeString(inputString, parse = true) {
  let outputString = DOMPurify.sanitize(inputString);
  outputString = linkifyHtml(outputString).replace(/href/g, "target='_blank' href");
  return parse ? parseHTML(outputString) : outputString;
}
